import React from 'react';
import styled from 'styled-components';

import colors from '../../../utils/colors';
import Text from '../../../components/Text';

import * as Styles from './styles';

interface ITechIconProps {
  colorOne: string;
  colorTwo: string;
  src: string;
  text: string;
  imgSize?: number;
}

const TechImageDiv: any = styled.img`
  height: ${({ imgSize = 65 }: any) => imgSize}%;
  width: ${({ imgSize = 65 }: any) => imgSize}%;
  -o-object-fit: cover;
  object-fit: contain;
`;

const TechIcon: React.FC<ITechIconProps> = ({
  colorOne,
  colorTwo,
  src,
  text,
  imgSize,
}) => {
  return (
    <>
      <Styles.Container>
        <Styles.TechDiv
          style={{ background: `linear-gradient(-145deg, ${colorOne}, ${colorTwo})` }}
        >
          <TechImageDiv src={src} imgSize={imgSize} />
        </Styles.TechDiv>

        <Text
          text={text}
          align="center"
          color={colors.gray.light02}
          size={8}
          weight="500"
          marginTop={4}
        />
      </Styles.Container>
    </>
  );
};

export default TechIcon;
