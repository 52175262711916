import euCeiImg from '../assets/projectsPics/euCeiImg.webp';

import airCnC01 from '../assets/projectsPics/airCnC01.webp';
import airCnC02 from '../assets/projectsPics/airCnC02.webp';
import airCnC03 from '../assets/projectsPics/airCnC03.webp';

import ecoleta from '../assets/projectsPics/ecoleta.webp';
import ecoleta02 from '../assets/projectsPics/ecoleta02.webp';
import ecoleta03 from '../assets/projectsPics/ecoleta03.webp';
import ecoleta04 from '../assets/projectsPics/ecoleta04.webp';

import mapaDev from '../assets/projectsPics/mapaDev.webp';

import beTheHero01 from '../assets/projectsPics/beTheHero01.webp';
import beTheHero02 from '../assets/projectsPics/beTheHero02.webp';
import beTheHero03 from '../assets/projectsPics/beTheHero03.webp';
import beTheHero04 from '../assets/projectsPics/beTheHero04.webp';
import beTheHero05 from '../assets/projectsPics/beTheHero05.webp';
import beTheHero06 from '../assets/projectsPics/beTheHero06.webp';

import goBarber01 from '../assets/projectsPics/goBarber01.webp';
import goBarber02 from '../assets/projectsPics/goBarber02.webp';
import goBarber03 from '../assets/projectsPics/goBarber03.webp';
import goBarber04 from '../assets/projectsPics/goBarber04.webp';

import clinicaCuidar01 from '../assets/projectsPics/clinicaCuidar01.webp';
import clinicaCuidar02 from '../assets/projectsPics/clinicaCuidar.webp';

import happy01 from '../assets/projectsPics/happy01.webp';
import happy02 from '../assets/projectsPics/happy02.webp';
import happy03 from '../assets/projectsPics/happy03.webp';
import happy04 from '../assets/projectsPics/happy04.webp';
import happy05 from '../assets/projectsPics/happy05png.webp';

import elixr01 from '../assets/projectsPics/elixr01.webp';
import elixr02 from '../assets/projectsPics/elixr02.webp';

import podcastr02 from '../assets/projectsPics/podcastr02.webp';
import podcastr01 from '../assets/projectsPics/podcastr01.webp';

import goNutri02 from '../assets/projectsPics/goNutri02.webp';
import goNutri01 from '../assets/projectsPics/goNutri01.webp';

import kidzies02 from '../assets/projectsPics/kidzies02.webp';
import kidzies01 from '../assets/projectsPics/kidzies01.webp';

import odan01 from '../assets/projectsPics/odan01.webp';
import odan02 from '../assets/projectsPics/odan02.webp';

import Roenenharia01 from '../assets/projectsPics/Roenenharia01.webp';
import Roenenharia02 from '../assets/projectsPics/Roengenharia02.webp';

const projectsList = [
  {
    id: 'project_01',
    imgArray: [euCeiImg],
    gitHubLink: undefined,
    siteLink: undefined,
    hasMobile: true,
    hasDesktop: true,
  },

  {
    id: 'project_02',
    imgArray: [airCnC01, airCnC02, airCnC03],
    gitHubLink: 'https://github.com/guibafica?tab=repositories&q=AirCnC&type=&language=&sort=',
    siteLink: undefined,
    hasMobile: true,
    hasDesktop: true,
  },

  {
    id: 'project_03',
    imgArray: [ecoleta, ecoleta02, ecoleta03, ecoleta04],
    gitHubLink: 'https://github.com/guibafica/ECOLETA',
    siteLink: undefined,
    hasMobile: true,
    hasDesktop: true,
  },

  {
    id: 'project_04',
    imgArray: [mapaDev],
    gitHubLink: 'https://github.com/guibafica?tab=repositories&q=MapaDev&type=&language=',
    siteLink: undefined,
    hasMobile: true,
    hasDesktop: true,
  },

  {
    id: 'project_05',
    imgArray: [
      beTheHero01, beTheHero02, beTheHero03, beTheHero04, beTheHero05,
      beTheHero06,
    ],
    gitHubLink: 'https://github.com/guibafica?tab=repositories&q=BeTheHero&type=&language=',
    siteLink: undefined,
    hasMobile: true,
    hasDesktop: true,
  },

  {
    id: 'project_06',
    imgArray: [goBarber01, goBarber02, goBarber03, goBarber04],
    gitHubLink: 'https://github.com/guibafica?tab=repositories&q=goBarber-2&type=&language=',
    siteLink: undefined,
    hasMobile: true,
    hasDesktop: true,
  },

  {
    id: 'project_07',
    imgArray: [clinicaCuidar02, clinicaCuidar01],
    gitHubLink: undefined,
    siteLink: 'https://cuidarclinica.com.br/',
    hasMobile: false,
    hasDesktop: true,
  },

  {
    id: 'project_08',
    imgArray: [happy01, happy02, happy03, happy04, happy05],
    gitHubLink: 'https://github.com/guibafica?tab=repositories&q=happ&type=&language=&sort=',
    siteLink: undefined,
    hasMobile: true,
    hasDesktop: true,
  },

  {
    id: 'project_09',
    imgArray: [elixr01, elixr02],
    gitHubLink: 'https://github.com/guibafica/ELIXR_venda_de_imoveis',
    siteLink: 'https://elixr-guibafica.vercel.app/',
    hasMobile: false,
    hasDesktop: true,
  },

  {
    id: 'project_10',
    imgArray: [podcastr02, podcastr01],
    gitHubLink: 'https://github.com/guibafica/Podcastr_NLW5',
    siteLink: undefined,
    hasMobile: false,
    hasDesktop: true,
  },

  {
    id: 'project_11',
    imgArray: [goNutri02, goNutri01],
    gitHubLink: undefined,
    siteLink: undefined,
    hasMobile: false,
    hasDesktop: true,
  },

  {
    id: 'project_12',
    imgArray: [kidzies02, kidzies01],
    gitHubLink: 'https://github.com/guibafica/Kindergarten_frontend',
    siteLink: undefined,
    hasMobile: false,
    hasDesktop: true,
  },

  {
    id: 'project_13',
    imgArray: [odan02, odan01],
    gitHubLink: 'https://github.com/guibafica/ODAN',
    siteLink: 'https://odan.vercel.app/',
    hasMobile: false,
    hasDesktop: true,
  },

  {
    id: 'project_14',
    imgArray: [Roenenharia02, Roenenharia01],
    gitHubLink: undefined,
    siteLink: 'https://www.engenhariaro.com.br/',
    hasMobile: false,
    hasDesktop: true,
  },
];

export default projectsList;
