import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Text from '../../components/Text';
import Translator from '../../components/I18n/Translator';
import colors from '../../utils/colors';

import engenierLogo from '../../assets/engenierLogo.svg';
import rocketLogo from '../../assets/rocketLogo.svg';

import * as Styles from './styles';

const FormationSlickSlider: React.FC = () => {
  const [aosConfig, setAosConfig] = useState({});

  useEffect(() => {
    AOS.init();

    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,

      autoplay: true,
      autoplaySpeed: 6000,
      cssEase: "ease-in-out",
      speed: 1000,
    };

    setAosConfig(settings);
  }, []);

  return (
    <Styles.Container data-aos="zoom-in-left" data-aos-duration="1000">
      <Styles.ContainerBackgroundLineOne />
      <Styles.ContainerBackgroundLineTwo />
      <Styles.ContainerBackgroundLineThree />

      <Text
        text={Translator('about.formationsTitle')}
        align="center"
        color={colors.gray.light02}
        size={10}
        weight="500"
        marginTop={4}
        fontFamily="Roboto Slab"
      />

      <Styles.StyledSlider {...aosConfig}>
        <Styles.SliderContainer>
          <Styles.SliderImage src={rocketLogo} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.formationsRocketTitle')}
              align="left"
              color={colors.gray.light03}
              size={10}
              weight="700"
              fontFamily="Dosis"

            />

            <Text
              text={Translator('about.formationsRocketText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>

        <Styles.SliderContainer>
          <Styles.SliderImage src={engenierLogo} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.formationsReactTitle')}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="700"
              fontFamily="Dosis"
            />

            <Text
              text={Translator('about.formationsReactText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>
      </Styles.StyledSlider>
    </Styles.Container>
  );
};

export default FormationSlickSlider;
