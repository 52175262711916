import React, { Component } from "react";
import Slider from "react-slick";

import TechIcon from "./TechIcon";

import typescripLogo from "../../assets/typescriptLogo02.png";
import htmlLogo from "../../assets/htmlLogo.png";
import cssLogo from "../../assets/cssLogo.png";
import nodeLogo from "../../assets/nodeLogo02.png";
import reactLogo from "../../assets/reactLogo.png";
import gitLogo from "../../assets/gitLogo.png";
import postgresLogo from "../../assets/postgresLogo.png";
import awslogo from "../../assets/awslogo.png";
import dockerlogo from "../../assets/dockerlogo.png";
import javascriptLogo02 from "../../assets/javascriptLogo02.png";
import sassLogo from "../../assets/sassLogo.png";
import gitLogo02 from "../../assets/gitLogo02.png";
import linuxLogo from "../../assets/linuxLogo.png";

import tailwindLogo from "../../assets/tailwindLogo.png";
import azureLogo from "../../assets/azureLogo.png";
import expoLogo from "../../assets/expoLogo.png";
import mySqlLogo from "../../assets/mySqlLogo.png";
import nextJSLogo from "../../assets/nextJSLogo.png";
import nestJSLogo from "../../assets/nestJSLogo.png";
import graphQLLogo from "../../assets/graphQLLogo.png";
import pythonLon from "../../assets/pythonLon.png";
import CSharpLogo from "../../assets/CSharpLogo.png";
import phpLogo from "../../assets/phpLogo.png";
import javaLogo from "../../assets/javaLogo.png";
import adonisLogo from "../../assets/adonisLogo.png";
import vercelLogo from "../../assets/vercelLogo.png";
import viteLogo from "../../assets/viteLogo.png";

import { Container } from "./styles";

export default class MultipleItems extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      centerPadding: "60px",

      dots: true,
      infinite: true,

      autoplay: true,
      autoplaySpeed: 1500,
      cssEase: "cubic-bezier(0.600, -0.280, 0.735, 0.045)",
      speed: 500,

      slidesToShow: 7,
      slidesToScroll: 1,
      initialSlide: 0,

      responsive: [
        {
          breakpoint: 990,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            className: "center",
            centerMode: true,
            centerPadding: "80px",
          },
        },
      ],
    };

    return (
      <Container>
        <Slider {...settings}>
          <TechIcon
            text="JAVASCRIPT"
            colorOne="#f7e018"
            colorTwo="#f7e018"
            // src={JavaScriptLogo}
            src={javascriptLogo02}
          />

          <TechIcon
            text="TYPESCRIPT"
            colorOne="#007acc"
            colorTwo="#007acc"
            src={typescripLogo}
          />

          <TechIcon
            text="HTML"
            colorOne="#e34f26"
            colorTwo="#e34f26"
            src={htmlLogo}
            imgSize={80}
          />

          <TechIcon
            text="CSS"
            colorOne="#0074be"
            colorTwo="#0074be"
            src={cssLogo}
            imgSize={100}
          />

          <TechIcon
            text="NODE JS"
            colorOne="#90c53f"
            colorTwo="#90c53f"
            src={nodeLogo}
            imgSize={90}
          />

          <TechIcon
            text="REACT"
            colorOne="#ffffff"
            colorTwo="#dddddd"
            src={reactLogo}
            imgSize={130}
          />

          <TechIcon
            text="GIT"
            colorOne="#333"
            colorTwo="#000"
            src={gitLogo02}
            imgSize={85}
          />

          <TechIcon
            text="GIT HUB"
            colorOne="#e0614b"
            colorTwo="#e44c30"
            src={gitLogo}
            imgSize={80}
          />

          <TechIcon
            text="POSTGRESQL"
            colorOne="#30628a"
            colorTwo="#234569"
            src={postgresLogo}
            imgSize={70}
          />

          <TechIcon
            text="AWS"
            colorOne="#f2f2f2"
            colorTwo="#dad8d8"
            src={awslogo}
            imgSize={120}
          />

          <TechIcon
            text="DOCKER"
            colorOne="#228ee1"
            colorTwo="#375c9f"
            src={dockerlogo}
            imgSize={85}
          />

          <TechIcon
            text="SASS"
            colorOne="#d17ca7"
            colorTwo="#cf649a"
            src={sassLogo}
            imgSize={85}
          />

          <TechIcon
            text="LINUX"
            colorOne="#f2f2f2"
            colorTwo="#dad8d8"
            src={linuxLogo}
            imgSize={85}
          />

          <TechIcon
            text="TAILWIND"
            colorOne="#ffffff"
            colorTwo="#ffffff"
            src={tailwindLogo}
            imgSize={100}
          />

          <TechIcon
            text="AZURE"
            colorOne="#f8f8f8"
            colorTwo="#f8f8f8"
            src={azureLogo}
            imgSize={100}
          />

          <TechIcon
            text="EXPO"
            colorOne="#f2f2f2"
            colorTwo="#dad8d8"
            src={expoLogo}
            imgSize={85}
          />

          <TechIcon
            text="MYSQL"
            colorOne="#eb8d11"
            colorTwo="#dc7d11"
            src={mySqlLogo}
            imgSize={85}
          />

          <TechIcon
            text="NEXTJS"
            colorOne="#70cfca"
            colorTwo="#dad8d8"
            src={nextJSLogo}
            imgSize={85}
          />

          <TechIcon
            text="NESTJS"
            colorOne="#da234d"
            colorTwo="#da234d"
            src={nestJSLogo}
            imgSize={85}
          />

          <TechIcon
            text="GRAPHQL"
            colorOne="#5e3c90"
            colorTwo="#2f214b"
            src={graphQLLogo}
            imgSize={85}
          />

          <TechIcon
            text="PYTHON"
            colorOne="#264e72"
            colorTwo="#264e72"
            src={pythonLon}
            imgSize={85}
          />

          <TechIcon
            text="CSHARP"
            colorOne="#400674"
            colorTwo="#9247b5"
            src={CSharpLogo}
            imgSize={85}
          />

          <TechIcon
            text="PHP"
            colorOne="#313355"
            colorTwo="#8897cc"
            src={phpLogo}
            imgSize={90}
          />

          <TechIcon
            text="JAVA"
            colorOne="#e8e8e8"
            colorTwo="#e8e8e8"
            src={javaLogo}
            imgSize={150}
          />

          <TechIcon
            text="ADONIS"
            colorOne="#5844f8"
            colorTwo="#5844f8"
            src={adonisLogo}
            imgSize={85}
          />

          <TechIcon
            text="VERCEL"
            colorOne="#7a26b7"
            colorTwo="#e30e87"
            src={vercelLogo}
            imgSize={85}
          />

          <TechIcon
            text="VITE"
            colorOne="#783f9d"
            colorTwo="#39748f"
            src={viteLogo}
            imgSize={85}
          />
        </Slider>
      </Container>
    );
  }
}
