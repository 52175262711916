import styled, { keyframes } from 'styled-components';

import colors from '../../utils/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const AboutContainer = styled.div`
  background: ${colors.gray.grayDark07};
  padding: 3rem;
  overflow: hidden !important;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AboutContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  max-width: 1280px;

  @media screen and (max-width: 990px) {
    flex-direction: column;
    justify-content: center;
  };
`;

export const VerticalColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  z-index: 2;

  @media screen and (max-width: 990px) {
    width: 100%;
  };
`;

export const ImageDiv = styled.img`
  height: 250px;
  width: 250px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  background: ${colors.gray.grayLight08};
  z-index: 1;
  border: 2px solid ${colors.gray.grayLight01};
  transition: all 0.4s ease-in-out;
  z-index: 3;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 990px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  };
`;

export const BorderImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 500px) {
   overflow: hidden;
    width: 100vw;
  }
`;

export const RotateDivOne = styled.div`
  border: 2px solid ${colors.gray.light02};
  padding: 5px;
  height: 280px;
  width: 280px;
  position: absolute;
  animation: ${rotate} 5s linear infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  z-index: 2;
`;

export const RotateDivTwo = styled.div`
  border: 2px solid ${colors.primary.green01};
  padding: 5px;
  height: 290px;
  width: 290px;
  position: absolute;
  animation: ${rotate} 3s linear infinite;
  border-radius: 19% 81% 34% 66% / 29% 57% 43% 71%;
  z-index: 2;
`;

export const RotateDivThree = styled.div`
  border: 2px solid ${colors.gray.grayLight04};
  padding: 5px;
  height: 300px;
  width: 300px;
  position: absolute;
  animation: ${rotate} 4s linear infinite;
  border-radius: 50% 50% 53% 47% / 22% 20% 80% 78%;
  z-index: 2;
`;

export const BackgroundEffectVOne = styled.div`
  /* height: 500px;
  width: 500px;
  position: absolute;
  border-radius: 0% 100% 100% 0% / 0% 0% 100% 100% ;
  background: ${colors.gray.grayDark07};
  z-index: 1;
  margin-left: -200px;
  margin-top: -140px;
  transform: rotate(20deg); */

  margin: 0;
  position: absolute;
  left: 0;
  padding: 10px;
  margin-top: -300px;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::after {
    top: 9px;
    border-color: transparent transparent transparent ${colors.gray.grayDark07};
    border-width: 500px;
    left: 0;
    z-index: 2;
  }

  @media screen and (max-width: 990px) {
    display: none;
  }
`;

export const BackgroundEffectVTwo = styled.div`
  /* height: 500px;
  width: 500px;
  position: absolute;
  right: 0;
  border-radius: 100% 0% 0% 100% / 0% 100% 0% 100%;
  background: ${colors.gray.grayDark07};
  z-index: 1;
  margin-right: -200px;
  margin-top: -140px;
  transform: rotate(-20deg); */

  margin: 0;
  position: absolute;
  right: 0;
  padding: 10px;
  margin-top: -300px;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::after {
    top: 9px;
    border-color: transparent transparent transparent ${colors.gray.grayDark07};
    border-width: 500px;
    transform: rotate(180deg);
    right: 0;
    z-index: 2;
  }

  @media screen and (max-width: 990px) {
    display: none;
  }
`;

export const BackgroundEffectOne = styled.div`
  height: 400px;
  width: 400px;
  position: absolute;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: ${colors.gray.grayDark01};
  z-index: 2;
  margin-left: -200px;
  margin-top: -100px;
  filter: blur(8px);
`;

export const BackgroundEffectTwo = styled.div`
  height: 300px;
  width: 300px;
  position: absolute;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: ${colors.primary.lightGreen01};
  z-index: 2;
  margin-left: -200px;
  filter: blur(100px);
`;

export const BackgroundEffectThree = styled.div`
  height: 300px;
  width: 300px;
  right: 0;
  position: absolute;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: ${colors.gray.grayDark01};
  z-index: 2;
  margin-top: 100px;
  filter: blur(8px);
  transform: rotate(180deg);

  @media screen and (max-width: 780px) {
    display: none;
  }
`;

export const BackgroundEffectFour = styled.div`
  height: 150px;
  width: 150px;
  right: 0;
  position: absolute;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: ${colors.primary.lightGreen01};
  z-index: 2;
  margin-top: 200px;
  margin-right: 100px;
  filter: blur(80px);
  transform: rotate(180deg);

  @media screen and (max-width: 780px) {
    display: none;
  }
`;

export const SkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  max-width: 1280px;

  @media screen and (max-width: 990px) {
    margin-top: 0;
  }
`;

export const SkillsDividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 10%;
  margin-top: 80px;

  @media screen and (max-width: 500px) {
    width: 30%;
  }
`;

export const DividerDot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${colors.primary.green01};  
`;

export const TechsContent = styled.div`
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TechsContainer = styled.div`
  width: 100%;
`;

export const FormacaoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;

  @media screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const TypedDiv = styled.div`
  height: 250px;

  span {
    text-align: left;
    color: ${colors.gray.light02};
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width: 990px) {
    height: 200px;
  };

  @media screen and (max-width: 600px) {
    margin-bottom: 40px;
  };
`;

export const HeroBtnWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin: 0; 
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Dosis';
  cursor: pointer;
  border: 1px solid ${colors.gray.grayLight07};

  &:hover {
    border: none;
  } 
`;

export const Button = styled.button`
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: ${colors.gray.white};
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: 12px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  font-weight: 500;
  background: transparent;
  border: none;

  &:hover {
    color: ${colors.gray.black};
    background: ${colors.primary.green01};
    box-shadow: 0 0 10px ${colors.primary.green01}, 
                0 0 40px ${colors.primary.green01}, 
                0 0 80px ${colors.primary.green01};
    transition-delay: 0.5s;
    transform: scale(1.1);

    span {
      &:nth-child(1) {
        left: 100%;
        transition: 0.5s;
      }

      &:nth-child(2) {
        top: 100%;
        transition: 0.5s;
        transition-delay: 0.125s;
      }

      &:nth-child(3) {
        right: 100%;
        transition: 0.5s;
        transition-delay: 0.25s;
      }

      &:nth-child(4) {
        bottom: 100%;
        transition: 0.5s;
        transition-delay: 0.375s;
      }
    }
  }

  span {
    position: absolute;
    display: block;

    &:nth-child(1) {
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, ${colors.primary.green01});
    }

    &:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, ${colors.primary.green01});
    }

    &:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, ${colors.primary.green01});
    }

    &:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, ${colors.primary.green01});
    }
  }
`;
