import React, { useCallback } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Text from "../../components/Text";
import Translator from "../../components/I18n/Translator";
import colors from "../../utils/colors";
import i18n from "../../config/i18n";

import * as Styles from "./styles";

const ContactSection: React.FC = () => {
  AOS.init();

  const handleNavigateToWpp = useCallback(() => {
    let message = "Olá Guilherme, tenho um projeto incrível para nós!";

    if (i18n.language === "en-US") {
      message = "Hi Guilherme, I have an amazing project for us!";
    }

    //const wppURL = `https://api.whatsapp.com/send?l=pt-BR&phone='5573991115093'&text=${message}`;
    const wppURL = `https://api.whatsapp.com/send?l=pt-BR&phone='14168972834'&text=${message}`;

    window.open(wppURL, "_blank")!.focus();
  }, []);

  return (
    <>
      <Styles.ContactContainer id="contact">
        <Styles.BackgroundDiv />

        <Styles.Content
          data-aos="zoom-out"
          data-aos-duration="500"
          data-aos-offset="-10"
        >
          <Text
            text={Translator("contact.title")}
            align="center"
            color={colors.primary.green01}
            size={14}
            weight="400"
            fontFamily="Roboto Slab"
            letterSpacing={2}
            marginTop={-80}
          />

          <Styles.ContactMessageDiv onClick={handleNavigateToWpp}>
            <Text
              text={Translator("contact.subTitle")}
              align="center"
              color={colors.gray.light01}
              size={50}
              weight="400"
              fontFamily="Roboto Slab"
            />

            <Styles.ArrowForwardTwo />
          </Styles.ContactMessageDiv>
        </Styles.Content>
      </Styles.ContactContainer>
    </>
  );
};

export default ContactSection;
