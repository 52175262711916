import styled from 'styled-components';

import colors from '../../utils/colors';

export const Container = styled.div`
  margin: 0; 
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Dosis';
  cursor: pointer;
  border: 1px solid ${colors.gray.grayLight07};

  &:hover {
    border: none;
  } 
`;
