import React, { Fragment, useCallback, useState, useEffect } from "react";

import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import LoaderContainer from '../../components/LoaderContainer';

import HomeSection from '../../sections/HomeSection';
import AboutSection from '../../sections/AboutSection';
import ServicesSection from '../../sections/ServicesSection';
import PortfolioSection from '../../sections/PortfolioSection';
import ContactSection from '../../sections/ContactSection';
import FooterSection from '../../sections/FooterSection';

const Dashboard: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const loader = useCallback(() => {
    // @ts-ignore: Unreachable code error
    document.querySelector('.loader-container').classList.add('fade-out');
  }, []);

  const fadeOut = useCallback(() => {
    setInterval(loader, 2000);

    return
  }, [loader]);

  useEffect(() => {
    window.onload = fadeOut;

    console.log(' ');
    console.log(' ');
    console.log(
      '%c Made with ❤️ by Guilherme Bafica!',
      'color: #01bf71; background: #010606; border-radius: 4px; padding: 10px; font-size: 12px; font-weight: bold'
    );
    console.log(' ');
    console.log(' ');
  }, [fadeOut]);

  return (
    <Fragment>
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />

      <LoaderContainer />

      <HomeSection />
      <AboutSection />
      <ServicesSection />
      <PortfolioSection />
      <ContactSection />
      <FooterSection />

      <Footer />
    </Fragment>
  )
}

export default Dashboard;
