import styled from 'styled-components';

import colors from '../../utils/colors';

import projectBackground from '../../assets/projectBackground.png';

export const Container = styled.div`
  height: 70vh;
  width: 30%;
  border-radius: 8px;
  background-color: ${colors.gray.grayDark01};
  box-shadow: 0 0 5px ${colors.primary.lightGreen01};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  
  &+div {
    margin-left: 2rem;
  }

  @media screen and (max-width: 750px) {
    width: 80%;
    margin-bottom: 2rem;

    &+div {
      margin-left: 0rem;
    }
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }

  &:hover {
    /* cursor: pointer; */
    box-shadow: 0 0 20px ${colors.primary.lightGreen01};
  }
`;

export const ProjectCardImgDiv = styled.div`
  height: 60%;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px dashed ${colors.primary.green01};
`;

export const ProjectCardImg = styled.img`
  height: 100%;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -o-object-fit: cover;
  object-fit: cover;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ProjectCardContent = styled.div`
  height: 40%;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: url(${projectBackground}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const MoreInformationDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ProjectPlatformDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MoreInformationButton = styled.button`
  background-color: transparent;
  border: 1px solid ${colors.primary.lightGreen01};
  color: ${colors.primary.green01};
  padding: 0.2rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  /* border-radius: 4px; */
  border-radius: 95% 4% 97% 5%/4% 94% 3% 95%;
  transition: all 0.3s ease-in-out;

  a {
    color: ${colors.primary.lightGreen01};
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.gray.light03};
    border: 1px solid ${colors.gray.light03};
    transform: scale(1.1);
    border-radius: 4% 95% 6% 95%/95% 4% 92% 5%;

    a {
      color: ${colors.gray.white};
    }
  }
`;
