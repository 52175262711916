import React from 'react';

import Translator from '../../components/I18n/Translator';

import * as Styles from './styles';

interface INavBarProps {
  isOpen: boolean;
  toggle: () => void;
}

const Sidebar: React.FC<INavBarProps> = ({ isOpen, toggle }) => {
  return (
    <>
      <Styles.SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Styles.Icon onClick={toggle}>
          <Styles.CloseIcon />
        </Styles.Icon>

        <Styles.SideBarWrapper>
          <Styles.SidebarMenu>
            <Styles.SidebarLink to="home" onClick={toggle}>
              {Translator('navbar.home')}
            </Styles.SidebarLink>

            <Styles.SidebarLink to="about" onClick={toggle}>
              {Translator('navbar.about')}
            </Styles.SidebarLink>

            <Styles.SidebarLink to="services" onClick={toggle}>
              {Translator('navbar.services')}
            </Styles.SidebarLink>

            <Styles.SidebarLink to="portfolio" onClick={toggle}>
              {Translator('navbar.portfolio')}
            </Styles.SidebarLink>

            <Styles.SidebarLink to="contact" onClick={toggle}>
              {Translator('navbar.contact')}
            </Styles.SidebarLink>
          </Styles.SidebarMenu>
        </Styles.SideBarWrapper>
      </Styles.SidebarContainer>
    </>
  );
};

export default Sidebar;
