import styled from 'styled-components';
import Slider from "react-slick";

import footer from '../../assets/footer-agency.png';

import colors from '../../utils/colors';

export const Container = styled.div`
  width: 48%;
  height: 230px;
  border-radius: 12px;
  padding: 16px;
  background-color: ${colors.gray.grayDark04};
  box-shadow: 0 0 .1rem #fff,
              0 0 .1rem #fff,
              0 0 2rem #01bf7198,
              0 0 0.1rem #01bf7198,
              0 0 0.1rem #01bf7198,
              inset 0 0 0.1rem #01bf7198;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-image: url(${footer});

  &:hover {
    transform: scale(1.1);
    cursor: e-resize;
  }

  @media screen and (max-width: 1030px) {
    height: 250px;
  }

  @media screen and (max-width: 860px) {
    width: 100%;
    height: 250px;
    padding: 10px;
  }

  @media screen and (max-width: 500px) {
    height: 420px;
  }
`;

export const ContainerBackgroundLineOne = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.gray.grayDark07};
  transform: rotate(45deg);
  position: relative;
  left: 200px;
  top: 200px;
  z-index: 1;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

export const ContainerBackgroundLineTwo = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.gray.grayDark07};
  transform: rotate(45deg);
  position: relative;
  left: 200px;
  top: 250px;
  z-index: 1;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

export const ContainerBackgroundLineThree = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${colors.gray.grayDark07};
  transform: rotate(45deg);
  position: relative;
  left: 250px;
  top: 200px;
  z-index: 1;

  @media screen and (max-width: 860px) {
    display: none;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  justify-content: space-between !important;
  z-index: 3;
  position: relative;
  margin-bottom: 32px;

  @media screen and (max-width: 500px) {
    flex-direction: column !important;
  }
`;

export const SliderImage = styled.img`
  width: 35%;

  @media screen and (max-width: 500px) {
    width: 50%;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
`;

export const SliderTextDiv = styled.div`
  width: 60%;
  height: 70%;

  @media screen and (max-width: 500px) {
    width: 95%;
  }
`;

export const StyledSlider = styled(Slider)`
  z-index: 3;
  position: relative;
  margin-top: 16px;
`;

