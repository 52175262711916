export default {
  translations: {
    navbar: {
      home: 'Home',
      about: 'Quem sou',
      services: 'O que faço',
      portfolio: 'Portfólio',
      contact: 'Contato',
    },

    home: {
      title: 'Não vendo produtos, lhe ofereço uma experiência personalizada.',
      subTitle: 'Pronto para transformar as suas ideias em realidade?',
      button: 'INICIAR',
    },

    about: {
      citationTitle: 'CITAÇÃO',
      citationSubTittle: 'Treine enquanto eles dormem, estude enquanto eles se divertem, persista enquanto eles descansam, e então, viva o que eles sonham.',
      citationWriter: 'Provérbio japonês',

      occupationTitle: 'OCUPAÇÃO ATUAL',
      occupationSubTitle: 'Desenvolvedor full-stack web e mobile',

      hello: 'Oi,',
      cvDownload: 'BAIXAR CV',

      skillsTitle: '- HABILIDADES E FORMAÇÃO -',

      differentialsTitle: '- DIFERENCIAIS -',

      differentialsEngineeringTitle: 'Engenharia de Software',
      differentialsEngineeringText: 'Utilizo metodologias e planejamentos otimizados que dinamizam o fluxo do projeto, desde a etapa de planejamento, até o momento da entrega.',

      differentialsSubmitTitle: 'Entrega Contínua',
      differentialsSubmitText: 'Baseado em feedbacks e interações constantes com o cliente, irei sempre enviar atualizações do projeto, garantindo uma fluidez durante o processo de desenvolvimento.',

      differentialsClearTitle: 'Clear Code',
      differentialsClearText: 'Sempre utilizo as melhores práticas do mercado, pois a qualidade dos meus códigos é o fator mais importante.',

      differentialsChangesTitle: 'Alterações Ilimitadas',
      differentialsChangesText: 'Durante o processo de desenvolvimento, o cliente tem a possibilidade de fazer quantas alterações forem necessárias.',

      differentialsWorkTitle: 'Trabalho Remoto',
      differentialsWorkText: 'Todo o processo de desenvolvimento do projeto, poderá ser feito remotamente. Receba seu produto no conforto da sua casa.',

      differentialsReactTitle: 'Biblioteca React',
      differentialsReactText: 'Trabalho com as mesmas tecnologias que são utilizadas por gigantes do mercado como: Netflix, Whatsapp web, Instagram, Airbnb, Walmart, Uber, Magazine Luiza...',

      formationsTitle: '- FORMAÇÕES -',

      formationsRocketTitle: 'ROCKETSEAT 🚀',
      formationsRocketText: 'Formado, em 2020 pela Rocketseat, no desenvolvimento híbrido utilizando a stack React.',

      formationsReactTitle: 'Engenharia da Computação 🧑‍🎓',
      formationsReactText: 'Líder, Representante e Graduando no curso superior de Engenharia da Computação.',
    },

    services: {
      tittle: 'O QUE EU FAÇO',

      webTitle: 'SITES',
      webSubTitle: 'Sistemas Web',
      webSubTextOne: 'Desenvolvimento de páginas únicas',
      webSubTextTwo: 'como portfolios, até sistemas completos de gestão para sua empresa.',

      appTitle: 'APLICATIVOS',
      appSubTitle: 'Híbridos Android e IOS',
      appSubTextOne: 'Desenvolvimento de aplicativos de forma',
      appSubTextTwo: 'híbrida, tanto para celulares Android, quanto para IOS.',

      apiTitle: 'API',
      apiSubTitle: 'Backend',
      apiSubTextOne: "Desenvolvimento de API's",
      apiSubTextTwo: 'no padrão RESTful com todas as rotas para o seu sistema.',

      button: 'SAIBA MAIS',
    },

    portfolio: {
      welcomeMessage: 'Bem-vindo ao meu portfólio!',
      button: 'Saiba mais',

      projectOneTitle: 'euCEI',
      projectOneSubTitle: 'Sistema de gestão de eventos para universidade.',

      projectTwoTitle: 'airCnC',
      projectTwoSubTitle: 'Coffee and Code: "clone" do airBnB.',

      projectThreeTitle: 'ECOLETA',
      projectThreeSubTitle: 'Mapa para pontos de coleta de resíduos.',

      projectFourTitle: 'MapaDev',
      projectFourSubTitle: 'Aplicação simples com geolocalização.',

      projectFiveTitle: 'BeTheHero',
      projectFiveSubTitle: 'Cadastro e Visualizador de ONGs.',

      projectSixTitle: 'goBarber',
      projectSixSubTitle: 'Sistema de agendamento no barbeiro.',

      projectSevenTitle: 'S. Hospitalar',
      projectSevenSubTitle: 'Sistema web completo de gestão hospitalar.',

      projectEightTitle: 'Happy',
      projectEightSubTitle: 'Ferramenta para promover orfanatos.',

      projectNineTitle: 'ELIXR',
      projectNineSubTitle: 'Website para imóveis.',

      projectTenTitle: 'Podcastr',
      projectTenSubTitle: 'Site para postagem de podcast.',

      projectElevenTitle: 'GoNutri',
      projectElevenSubTitle: 'Portfólio de Nutrição.',

      projectTwelveTitle: 'Kindergarten',
      projectTwelveSubTitle: 'Landing page creche.',

      projectThirteenTitle: 'ODAN',
      projectThirteenSubTitle: 'Acompanhamento da Ninhada.',

      projectFourteenTitle: 'RO Engenharia',
      projectFourteenSubTitle: 'Portfólio de Engenharia Civil.',
    },

    contact: {
      title: 'PRECISA DE UM SISTEMA?',
      subTitle: 'Vamos trabalhar juntos',
    },

    footer: {
      menuTitle: 'MENU',
      menuHome: 'Home',
      menuAbout: 'Sobre',
      menuServices: 'Serviços',
      menuPortfolio: 'Portfólio',
      menuContact: 'Contato',

      socialTitle: 'REDES SOCIAIS',
      socialGitHub: 'GitHub',
      socialLinkedIn: 'LinkedIn',
      socialInstagram: 'Instagram',

      contactTitle: 'CONTATO',
      contactWhatsApp: 'WhatsApp',
      contactEmail: 'Email',
      contactPhone: 'Telefone',

      reservedMessage: '© Copyright Guilherme Bafica Developer 2021 | Todos os Direitos Reservados',
    },

    projectDetail: {
      projectOneTextOne: `
        Esse projeto foi minha porta de entrada para o mundo da programação web e
        mobile. Desenvolvido juntamente com mais dois amigos, o euCEI foi vendido
        para o Centro de Empreendedorismo e Inovação na universidade Wyden Área 1
        Salvador.
      `,
      projectOneTextTwo: `
        Seu principal objetivo era, através do site, facilitar o cadastro de eventos
        pelos coordenadores da universidade, onde também, eles iriam poder gerenciar
        os inscritos, atualizar informações, e até mesmo deletar o seu evento 
        cadastrado. Pela web ainda, o coordenador, ao fim do evento, poderia facilmente
        gerar a carga horária complementar dos alunos inscritos.

        Por outro lado, no aplicativo, os alunos cadastrados, teriam a possibilidade
        e facilidade de visualizar, em um só lugar, todos os eventos que estão acontecendo
        no campus, com um filtro de interesse. Caso interessado, o aluno poderia se inscrever
        com apenas um clique.
      `,

      projectTwoTextOne: `
        Meu primeiro projeto web e mobile. Tinha a premissa de funcionamento baseada
        no AirBnB, porém, voltado ao mundo dev.  
      `,
      projectTwoTextTwo: `
        No site, os usuários poderiam cadastrar locais voltados a programação, espaços
        esses que seriam alugados por outras pessoas que teriam interesse no espaço. 

        Já no aplicativo, os interessados poderiam solicitar a reserva desses espaços 
        previamente cadastrados.
      `,

      projectThreeTextOne: `
        A aplicação tem como funcionalidade ajudar pessoas a encontrarem pontos de 
        coleta de forma eficiente. 
      `,
      projectThreeTextTwo: `
        Com a ajuda de um mapa, o usuário consegue facilmente encontrar pontos 
        previamente cadastrados, nas proximidades da sua atual localização. O sistema
        possui também um filtro, onde há a possibilidade de selecionar exatamente 
        o material que gostaria de descartar.

        As empresas de coleta possuem a funcionalidade de cadastrar seus pontos e
        o tipo de material de coleta.
      `,

      projectFourTextOne: `
        Nessa aplicação, houve a implementação de mapa, com isso, pode-se visualizar
        pessoas cadastradas que estão em sua volta. 
      `,
      projectFourTextTwo: `
        O intuito seria encontrar programadores próximos, tanto em localidade, 
        quanto em interesse.
      `,

      projectFiveTextOne: `
        Funcionalidades que possibilitam Heróis, visualizarem e ajudarem ONGs 
        cadastradas na plataforma.
      `,
      projectFiveTextTwo: `
        Em resumo, através do site, as ONGs podem cadastrar-se, com nome, valores,
        descrição do caso e localidade. Com isso, pelo aplicativos, pessoas interessadas
        podem visualizar essas informações, entrar em contato e ajudar na causa.
      `,

      projectSixTextOne: `
        Tem como objetivo auxiliar o fluxo de clientes em uma barbearia, com 
        facilidade no agendamento e gerenciamento dos clientes e profissionais.
      `,
      projectSixTextTwo: `
        Meu primeiro projeto focado exclusivamente em boas práticas, nele, fui instruido
        a aplicar as melhores técnicas do mercado na época. Adquiri muito conhecimento
        que aplico nos meus projetos atualmente.
      `,

      projectSevenTextOne: `
        Esse projeto tinha como principal funcionalidade auxiliar e dinamizar o fluxo
        de agendamentos em uma clínica hospitalar. Com isso, podendo oferecer um serviço
        com maior taxa de qualidade, eficiência, velocidade e organização.
      `,
      projectSevenTextTwo: `
        De uso exclusivo para funcionários da clínica, cada usuário possuia um determinado
        nível de acesso dentro do sistema, como exemplo dos administradores, que 
        tinham a permissão de cadastrar novos funcionários.
        O sistema era dividido em módulos: 
          Na seção de agendamentos, as recepcionistas
        poderiam selecionar o paciente, data, procedimento, para qual médico e até
        mesmo, escrever uma descrição sobre o caso.
          Na home, era exibido os exames cadastrados, em ordem de período e filtragem
        de médico, onde cada um tinha acesso isolado aos seus próprios agendamentos.
          O sistema possui tambem cadastro de paciente, onde cada um tem o seu histórico
        da ficha médica, podendo assim, o médico avaliar todos os procedimentos daquele
        paciente desde que o mesmo foi cadastrado no sistema, independente de quanto tempo
        isso tenha ocorrido, fica tudo salvo no sistema. Com isso, podemos aplicar
        uma medicina preventiva e mais completa. 
          No sistema existe a aba de notificações, que avisa por exemplo, aos
        recepcionistas quando determinado paciente precisa realizar um retorno, e 
        entrega a opção de enviar uma mensagem direta para o Whatsapp do paciente em 
        apenas 1 clique.
          Há a seção de controle do almoxarifado, avisando caso algum item ou medicamento
        esteja perto de vencer ou em baixa quantidade.
          E outro ponto importante, é o módulo de chat. Nele, os funcionários podem
        conversar entre si, em chats individuais, como se fosse um Whatsapp. Facilitando
        a comunicação entre setores, e até mesmo, servindo para avisar ao médico que
        seu paciente chegou.
      `,

      projectEightTextOne: `
        Este é o Happy, uma aplicação completa que tem como principal objetivo 
        promover orfanatos e trazer alegria as suas crianças.
      `,
      projectEightTextTwo: `
        Com o auxílio de um mapa, o usuário poderá visualizar orfanatos próximos 
        de sua localidade, com algumas informações sobre o mesmo, como o que é
        necessário para poder fazer uma visita.
      `,

      projectNineTextOne: `
        Projeto Website completamente Responsivo e com Animações. 
      `,
      projectNineTextTwo: `
        Este repositório tem fins de estudo, e representa a uma landing page de um 
        website para vendas, aluguel e divulgações de imóveis.
      `,

      projectTenTextOne: `
        Uma aplicação web com o intuito de ouvirmos um podcast. 
      `,
      projectTenTextTwo: `
        O site baseia-se em um único podcast, o objetivo é a postagem do conteúdo 
        oriundo de apenas uma fonte. 
      `,

      projectElevenTextOne: `
        Website pessoal para uma nutricionista, em forma de portfólio. 
      `,
      projectElevenTextTwo: `
        Neste projeto, encontra-se informações sobre a profissional, dicas de 
        alimentação em forma de cards, um layout detalhado, responsivo e com 
        animações. 
      `,

      projectTwelveTextOne: `
        Website com design detalhado e com bastante ilustrações. (Projeto em desenvolvimento)  
      `,
      projectTwelveTextTwo: `
        Este projeto simula uma landing page para uma creche ou escola infantil. Nele,
        os usuários encontrarão um design agradável e com um tom infantil. Durante o
        site, informações são exibidas, como pontos fortes da empresa, descrição, 
        brincadeiras e dinâmicas ofertadas, descrição dos professores, valores e 
        seção de contato. 
      `,

      projectThirteenTextOne: `
        ODAN - Observatório do Desenvolvimento e Acompanhamento da Ninhada. 
      `,
      projectThirteenTextTwo: `
        Projeto desenvolvido a fins de controlar a ninhada, onde deverá ser 
        mostrado informações como vacinação, localidade, nome do filhote e tutor atual. 
      `,

      projectFourteenTextOne: `
        RO ENGENHARIA é uma empresa de engenharia civil focada no desenvolvimento 
        de soluções em projetos e regularização de imóveis. Determinada a realizar sonhos, tendo como ponto primordial o atendimento 
        aos clientes e qualificação dos profissionais. 
      `,
      projectFourteenTextTwo: `
        Este projeto é um portfólio web que tem como objetivo exibir informações sobre
        a empresa como serviços, equipe e contato.
      `,

      anotherText: 'OUTROS PROJETOS',
    },

    notFound: {
      error: 'Erro ',
      titleError: 'Oops, página não encontrada.',
      infoError: `
        A página que você está procurando não existe, foi deletada, ou está
        temporariamente indisponível.
      `,
      finalError: `Não se preocupe, apenas `,
      buttonError: 'clique aqui e retorne para a home'
    },
  }
}
