/* eslint-disable */
const numbertoword = (number: any): string => {
  var th_val = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
  var dg_val = ['Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
  var tn_val = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
  var tw_val = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  number = number.toString();
  number = number.replace(/[\, ]/g, '');

  if (number != parseFloat(number)) return 'not a number';

  var x_val = number.indexOf('.');

  if (x_val == -1) x_val = number.length;
  if (x_val > 15) return 'too big';

  var n_val = number.split('');
  var str_val = '';
  var sk_val = 0;

  for (var i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == '1') {
        str_val += tn_val[Number(n_val[i + 1])] + ' ';

        i++;

        sk_val = 1;
      } else if (n_val[i] != 0) {
        str_val += tw_val[n_val[i] - 2] + ' ';

        sk_val = 1;
      }
    } else if (n_val[i] != 0) {
      str_val += dg_val[n_val[i]] + ' ';

      if ((x_val - i) % 3 == 0) str_val += 'hundred ';

      sk_val = 1;
    }

    if ((x_val - i) % 3 == 1) {
      if (sk_val) str_val += th_val[(x_val - i - 1) / 3] + ' ';

      sk_val = 0;
    }
  }

  if (x_val != number.length) {
    var y_val = number.length;

    str_val += 'point ';

    for (var j = x_val + 1; j < y_val; j++) {
      str_val += dg_val[n_val[i]] + ' ';
    }
  }

  return str_val.replace(/\s+/g, ' ');
};

export default numbertoword;
