import styled from 'styled-components';

import WelcomeBackground from '../../assets/welcomeBackground02.jpg';
// import WelcomeBackground from '../../assets/welcomeBackground08.jpg';

import colors from '../../utils/colors';

export const PortfolioContainer = styled.div`
  background: ${colors.gray.grayDark07};
  overflow: hidden !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WelcomeMessage = styled.div`
  background: url(${WelcomeBackground}) no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
`;

export const WelcomeMessageContent = styled.div`
  max-width: 45rem;
  margin-left: auto;
  text-align: center;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const TextBox = styled.div`
  background: linear-gradient(
    -90deg, 
    #000000 50%, 
    transparent 100%
  );
  padding-left: 2rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media screen and (max-width: 600px) {
    background: ${colors.gray.black};
    padding-right: 2rem;
    border-radius: 8px;
  }

  @media screen and (max-width: 450px) {
    width: 80%;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 3rem;
  background-color: ${colors.gray.grayDark07};
  max-width: 1280px;
`;

export const ThreeCardsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 750px) {
    flex-direction: column;
  }
`;

export const TwoCardsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 750px) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
`;
