
// @ts-nocheck
import React from 'react';

import ParticleConfig from './config/particle-config';

import * as Styles from './styles';

export default function ParticleBackground() {
  return (
    <Styles.StyledParticles params={ParticleConfig} />
  );
}
