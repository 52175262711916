import styled from 'styled-components';
import { MdArrowForward } from 'react-icons/md';

import background from '../../assets/home-bg.jpg';

import colors from '../../utils/colors';

export const ContactContainer = styled.div`
  background: #272d3b;
  overflow: hidden !important;
  width: 100%;
  height: 100vh;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackgroundDiv = styled.div`
  width: 100%;

  background: url(${background});
  -o-object-fit: cover;
  object-fit: cover;
  transform: rotate(180deg);

  position: relative;
  top: 0;
  left: 0;
  height: 500px;
  background-size: 100% 500px;
`;

export const ContactMessageDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 250px;
  padding-bottom: 1rem;
  z-index: 1;
  position: relative;
  transition: all 0.4s ease-in-out;
  cursor: pointer;

  &:hover {
    p {
      text-decoration: underline;
      color: ${colors.gray.light03};
    }

    svg {
      color: ${colors.gray.light03};
    }
  }

  @media screen and (max-width: 972px) {
    p {
      font-size: 50px;
    }
  }

  @media screen and (max-width: 670px) {
    border: 1px solid ${colors.gray.light01};
    margin-top: 1rem;
    width: 90%;

    p {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 600px) {
    p {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 460px) {
    p {
      font-size: 20px;
    }
  }
`;

export const ArrowForwardTwo = styled(MdArrowForward)`
  margin-left: 4px;
  font-size: 50px;
  margin-top: 16px;
  color: ${colors.gray.light01};

  @media screen and (max-width: 970px) {
    margin-top: 10px;
  }

  @media screen and (max-width: 970px) {
    font-size: 30px;
    margin-top: 5px;
  }
`;
