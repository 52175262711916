import React, { useCallback } from "react";
import { toast } from "react-toastify";
import AOS from "aos";
import "aos/dist/aos.css";

import Text from "../../components/Text";
import ParticleBackground from "../../components/ParticleBackground";
import Translator from "../../components/I18n/Translator";

import colors from "../../utils/colors";

import i18n from "../../config/i18n";

import * as Styles from "./styles";

const FooterSection: React.FC = () => {
  AOS.init();

  const handleCopyText = useCallback((type, text) => {
    // @ts-ignore: Unreachable code error
    if (window.clipboardData && window.clipboardData.setData) {
      // @ts-ignore: Unreachable code error
      return window.clipboardData.setData("Text", text);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      const textarea = document.createElement("textarea");

      textarea.textContent = text;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();

      try {
        return document.execCommand("copy");
      } catch (error) {
        let message = `Ocorreu um erro ao tentar copiar o ${type} para o clipboard!`;

        if (i18n.language === "en-US") {
          message = `An error occurred while trying to copy the ${type} to the clipboard!`;
        }

        toast(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "error",
        });

        return false;
      } finally {
        let message = `${type}  copiado para o clipboard!`;

        if (i18n.language === "en-US") {
          message = `${type} copied to clipboard!`;
        }

        toast(message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
        });

        document.body.removeChild(textarea);
      }
    }
  }, []);

  const handleNavigateToWeb = useCallback(
    (to: string) => {
      switch (to) {
        case "GitHub":
          window.open("https://github.com/guibafica", "_blank")!.focus();
          break;

        case "LinkedIn":
          window
            .open("https://www.linkedin.com/in/guilhermebafica/", "_blank")!
            .focus();
          break;

        case "Instagram":
          window
            .open("https://www.instagram.com/guibafica/", "_blank")!
            .focus();
          break;

        case "WhatsApp":
          let message = "Olá Guilherme, tenho um projeto incrível para nós!";

          if (i18n.language === "en-US") {
            message = "Hi Guilherme, I have an amazing project for us!";
          }

          const wppURL = `https://api.whatsapp.com/send?l=pt-BR&phone='14168972834'&text=${message}`;
          window.open(wppURL, "_blank")!.focus();

          break;

        case "Email":
          handleCopyText("Email", "gb.engcomp@hotmail.com");
          window.open("mailto:gb.engcomp@hotmail.com", "_blank")!.focus();

          break;

        case "Telefone":
          handleCopyText("Telefone", "14168972834");

          break;

        default:
          break;
      }
    },
    [handleCopyText]
  );

  return (
    <>
      <Styles.Container id="footer">
        <ParticleBackground />

        <Styles.ContainerHeader>
          <Text
            text="Guilherme Bafica"
            align="left"
            color={colors.gray.white}
            size={16}
            weight="500"
            fontFamily="Orelega One"
            letterSpacing={2}
          />

          <Styles.ContainerHeaderLine />
        </Styles.ContainerHeader>

        <Styles.Content>
          <Styles.ContentColumn
            data-aos="zoom-in-up"
            data-aos-duration="500"
            data-aos-offset="-100"
          >
            <Text
              text={Translator("footer.menuTitle")}
              align="center"
              color={colors.primary.green01}
              size={12}
              weight="500"
              fontFamily="Dosis"
              marginBottom={8}
            />

            <Styles.NavLinks to="home">
              <Styles.ContentOptionText>
                {Translator("footer.menuHome")}
              </Styles.ContentOptionText>
            </Styles.NavLinks>

            <Styles.NavLinks to="about">
              <Styles.ContentOptionText>
                {Translator("footer.menuAbout")}
              </Styles.ContentOptionText>
            </Styles.NavLinks>

            <Styles.NavLinks to="services">
              <Styles.ContentOptionText>
                {Translator("footer.menuServices")}
              </Styles.ContentOptionText>
            </Styles.NavLinks>

            <Styles.NavLinks to="portfolio">
              <Styles.ContentOptionText>
                {Translator("footer.menuPortfolio")}
              </Styles.ContentOptionText>
            </Styles.NavLinks>

            <Styles.NavLinks to="contact">
              <Styles.ContentOptionText>
                {Translator("footer.menuContact")}
              </Styles.ContentOptionText>
            </Styles.NavLinks>
          </Styles.ContentColumn>

          <Styles.ContentColumn
            data-aos="zoom-in-up"
            data-aos-duration="500"
            data-aos-delay="500"
            data-aos-offset="-100"
          >
            <Text
              text={Translator("footer.socialTitle")}
              align="center"
              color={colors.primary.green01}
              size={12}
              weight="500"
              fontFamily="Dosis"
              marginBottom={8}
            />

            <Styles.ContentOptionText
              onClick={() => handleNavigateToWeb("GitHub")}
            >
              {Translator("footer.socialGitHub")}
            </Styles.ContentOptionText>

            <Styles.ContentOptionText
              onClick={() => handleNavigateToWeb("LinkedIn")}
            >
              {Translator("footer.socialLinkedIn")}
            </Styles.ContentOptionText>

            <Styles.ContentOptionText
              onClick={() => handleNavigateToWeb("Instagram")}
            >
              {Translator("footer.socialInstagram")}
            </Styles.ContentOptionText>
          </Styles.ContentColumn>

          <Styles.ContentColumn
            data-aos="zoom-in-up"
            data-aos-duration="500"
            data-aos-delay="1000"
            data-aos-offset="-100"
          >
            <Text
              text={Translator("footer.contactTitle")}
              align="center"
              color={colors.primary.green01}
              size={12}
              weight="500"
              fontFamily="Dosis"
              marginBottom={8}
            />

            <Styles.ContentOptionText
              onClick={() => handleNavigateToWeb("WhatsApp")}
            >
              {Translator("footer.contactWhatsApp")}
            </Styles.ContentOptionText>

            <Styles.ContentOptionText
              onClick={() => handleNavigateToWeb("Email")}
            >
              {Translator("footer.contactEmail")}
            </Styles.ContentOptionText>

            <Styles.ContentOptionText
              onClick={() => handleNavigateToWeb("Telefone")}
            >
              {Translator("footer.contactPhone")}
            </Styles.ContentOptionText>
          </Styles.ContentColumn>
        </Styles.Content>

        <Styles.CopyrightDiv>
          <Text
            text={Translator("footer.reservedMessage")}
            align="center"
            color={colors.gray.dark02}
            size={8}
            weight="400"
            fontFamily="Dosis"
            marginBottom={-20}
          />
        </Styles.CopyrightDiv>
      </Styles.Container>
    </>
  );
};

export default FooterSection;
