/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';

import Translator from '../../components/I18n/Translator';

import * as Styles from './styles';

const NotFound: React.FC = () => {
  useEffect(() => {
    console.log(' ');
    console.log(' ');
    console.log(
      '%c Made with ❤️ by Guilherme Bafica.',
      'color: #01bf71; background: #010606; border-radius: 4px; padding: 10px; font-size: 12px; font-weight: bold'
    );
    console.log(' ');
    console.log(' ');
  }, []);

  return (
    <>
      <Styles.Container>
        <Styles.Noise />

        <Styles.Overlay />

        <Styles.Terminal>
          <h1>
            {Translator('notFound.error')}
            <Styles.ErrorCode>404</Styles.ErrorCode>
          </h1>

          <Styles.Output>{Translator('notFound.titleError')}</Styles.Output>

          <Styles.Output>{Translator('notFound.infoError')}</Styles.Output>

          <Styles.Output>
            {Translator('notFound.finalError')}
            <a href="/">{Translator('notFound.buttonError')}</a>.
          </Styles.Output>
        </Styles.Terminal>
      </Styles.Container>
    </>
  );
};

export default NotFound;
