import styled from 'styled-components';
import { Link } from 'react-scroll';

import colors from '../../utils/colors';

export const Container = styled.div`
  background: ${colors.gray.black};
  overflow: hidden !important;
  width: 100%;
  padding: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContainerHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ContainerHeaderLine = styled.div`
  width: 70%;
  height: 2px;
  margin-left: 5px;
  background-color: ${colors.gray.white};
  margin-top: 2px;

  @media screen and (max-width: 940px) {
    width: 60%;
  }

  @media screen and (max-width: 730px) {
    width: 50%;
  }

  @media screen and (max-width: 600px) {
    width: 40%;
  }

  @media screen and (max-width: 515px) {
    width: 30%;
  }

  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 60%;
  margin-top: 3rem;
  max-width: 1280px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    &+div {
      margin-top: 30px;
    }
  }
`;

export const ContentOptionText = styled.p`
  text-align: center;
  color: ${colors.gray.grayLight03};
  font-size: 18px;
  font-weight: 400;
  font-family: 'Dosis';
  margin-top: -4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${colors.gray.white};
    transform: translateX(10px);
  }
`;

export const CopyrightDiv = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${colors.gray.dark03};
  margin-top: 20px;
  padding-top: 20px;

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

export const NavLinks = styled(Link)`
  position: relative;
  z-index: 3;
`;
