import styled from "styled-components";

export const Container = styled.div`
  width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-top: 8px;
  margin-bottom: 8px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const TechDiv = styled.div`
  height: 90px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 12px;
  overflow: hidden;
`;
