// @ts-nocheck
import React, { useEffect, useRef, useCallback } from "react";
import Typed from "typed.js";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import AOS from "aos";
import "aos/dist/aos.css";

import Text from "../../components/Text";
import TechsSlickSlider from "../../components/TechsSlickSlider";
import DifferentialsSlickSlider from "../../components/DifferentialsSlickSlider";
import FormationSlickSlider from "../../components/FormationSlickSlider";
import Translator from "../../components/I18n/Translator";

import i18n from "../../config/i18n";

import colors from "../../utils/colors";

import profilePic from "../../assets/about4.png";
// import profilePic from '../../assets/pefilprocut.jpeg';
import CVPTBR from "../../assets/CV/CurriculoGuilhermeAugustoBafica.pdf";
import CVENUS from "../../assets/CV/GuilhermeBaficaResume.pdf";

import * as Styles from "./styles";

const AboutSection: React.FC = () => {
  AOS.init();

  const el = useRef(null);
  const typed = useRef(null);

  const handleDownloadCV = useCallback(() => {
    // Curriculo ta no careerflow

    try {
      if (i18n.language === "en-US") {
        saveAs(CVENUS, "Resume - Guilherme Bafica.pdf");
      } else {
        saveAs(CVPTBR, "Currículo - Guilherme Bafica.pdf");
      }
    } catch (error) {
      let message = `Ocorreu um erro ao tentar baixar o currículo.`;

      if (i18n.language === "en-US") {
        message = `An error occurred while trying to download the resume.`;
      }

      toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "error",
      });
    } finally {
      let message = "Currículo baixado com sucesso! :D";

      if (i18n.language === "en-US") {
        message = "Resume downloaded successfully! :D";
      }

      toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "success",
      });
    }
  }, []);

  useEffect(() => {
    let options = {
      strings: [
        `
        sou Guilherme Bafica, desenvolvedor 
full-stack e trabalho com programação 
desde 2019. ^1000
  Sempre preocupado com a boa 
experiência e segurança dos meus 
projetos, estou em constante busca 
das melhores tecnologias da atualidade, 
com objetivo de alcançar altas taxas 
de qualidade.   
              `,
      ],
      typeSpeed: 20,
      backSpeed: 1,
      loop: true,
      backDelay: 10000,
    };

    if (i18n.language === "en-US") {
      options = {
        strings: [
          `
        I'm Guilherme Bafica, full-stack 
developer and I work with programming 
since 2019. ^1000
  Always concerned about the good 
experience and security of my 
projects, i'm in constant search 
of the best technologies of today, 
aiming to achieve high rates 
of quality.  
          `,
        ],
        typeSpeed: 20,
        backSpeed: 1,
        loop: true,
        backDelay: 10000,
      };
    }

    typed.current = new Typed(el.current, options);

    return () => {
      typed.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <Styles.AboutContainer id="about">
        <Styles.BackgroundEffectVOne />
        <Styles.BackgroundEffectVTwo />

        <Styles.BackgroundEffectOne />
        <Styles.BackgroundEffectTwo />
        <Styles.BackgroundEffectThree />
        <Styles.BackgroundEffectFour />

        <Styles.AboutContent>
          <Styles.VerticalColumn data-aos="fade-right" data-aos-duration="500">
            <Text
              text={Translator("about.citationTitle")}
              align="left"
              color={colors.primary.green01}
              size={12}
              weight="500"
              fontFamily="Roboto Slab"
              letterSpacing={2}
              letter-spacing={10}
            />

            <Text
              text=" '' "
              align="left"
              color={colors.primary.green02}
              size={20}
              weight="500"
              fontFamily="Roboto Slab"
              letterSpacing={2}
              letter-spacing={10}
              marginTop={8}
              marginBottom={-12}
              marginLeft={-12}
            />

            <Text
              text={Translator("about.citationSubTittle")}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="500"
            />

            <Text
              text=" '' "
              align="right"
              color={colors.primary.green02}
              size={20}
              weight="500"
              fontFamily="Roboto Slab"
              letterSpacing={2}
              letter-spacing={10}
              marginTop={-8}
              marginBottom={-8}
              marginRight={-8}
            />

            <Text
              text={Translator("about.citationWriter")}
              align="right"
              color={colors.primary.green01}
              size={10}
              weight="400"
            />

            <Text
              text={Translator("about.occupationTitle")}
              align="left"
              color={colors.primary.green01}
              size={12}
              weight="500"
              fontFamily="Roboto Slab"
              letterSpacing={2}
              letter-spacing={10}
              marginTop={32}
            />

            <Text
              text={Translator("about.occupationSubTitle")}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="500"
              marginTop={8}
            />
          </Styles.VerticalColumn>

          <Styles.BorderImageDiv>
            <Styles.ImageDiv src={profilePic} />

            <Styles.RotateDivOne />
            <Styles.RotateDivTwo />
            <Styles.RotateDivThree />
          </Styles.BorderImageDiv>

          <Styles.VerticalColumn data-aos="fade-left" data-aos-duration="500">
            <Text
              text={Translator("about.hello")}
              align="left"
              color={colors.primary.green01}
              size={16}
              weight="500"
              fontFamily="Roboto Slab"
              letterSpacing={2}
              letter-spacing={10}
            />

            <Styles.TypedDiv className="type-wrap">
              <span style={{ whiteSpace: "pre" }} ref={el} />
            </Styles.TypedDiv>

            <Styles.HeroBtnWrapper
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="5000"
            >
              <Styles.ButtonContainer>
                <Styles.Button onClick={handleDownloadCV}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                  {Translator("about.cvDownload")}
                </Styles.Button>
              </Styles.ButtonContainer>
            </Styles.HeroBtnWrapper>
          </Styles.VerticalColumn>
        </Styles.AboutContent>

        <Styles.SkillsContainer>
          <Styles.TechsContent>
            <Styles.SkillsDividerContainer>
              <Styles.DividerDot />

              <Styles.DividerDot />

              <Styles.DividerDot />
            </Styles.SkillsDividerContainer>

            <Text
              text={Translator("about.skillsTitle")}
              align="center"
              color={colors.primary.green01}
              size={12}
              weight="500"
              fontFamily="Roboto Slab"
              letterSpacing={2}
              marginTop={8}
              marginBottom={24}
            />

            <Styles.TechsContainer>
              <TechsSlickSlider />
            </Styles.TechsContainer>

            <Styles.FormacaoContainer>
              <DifferentialsSlickSlider />

              <FormationSlickSlider />
            </Styles.FormacaoContainer>
          </Styles.TechsContent>
        </Styles.SkillsContainer>
      </Styles.AboutContainer>
    </>
  );
};

export default AboutSection;
