import React, { useState, useEffect, useCallback } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import { useTranslation } from "react-i18next";

import Translator from "../../components/I18n/Translator";

import colors from "../../utils/colors";

import i18n from "../../config/i18n";

import * as Styles from "./styles";

interface INavBarProps {
  toggle: () => void;
}

const Navbar: React.FC<INavBarProps> = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("pt-BR");

  const { i18n: i18nHook } = useTranslation();

  const changeNav = useCallback(() => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", changeNav);

    setSelectedLanguage(i18n.language);
  }, [changeNav]);

  const toggleHome = useCallback(() => {
    scroll.scrollToTop();
  }, []);

  const handleChangeSelectedLanguage = useCallback(
    (language: "pt-BR" | "en-US") => {
      setSelectedLanguage(language);

      i18nHook.changeLanguage(language);
    },
    [i18nHook]
  );

  return (
    <>
      <IconContext.Provider value={{ color: colors.gray.white }}>
        <Styles.Nav scrollNav={scrollNav}>
          <Styles.NavbarContainer>
            <Styles.InternationalizationDiv>
              {/* <Styles.NavLogo to="/" onClick={toggleHome}>
                Guilherme Bafica
              </Styles.NavLogo> */}
              <Styles.NavLogo to="/" onClick={toggleHome}>
                <h2>
                  <span>Guilherme </span>
                  <span>Bafica</span>
                </h2>
              </Styles.NavLogo>

              <Styles.FlagsDiv>
                <Styles.BrazilFlag
                  onClick={() => handleChangeSelectedLanguage("pt-BR")}
                  size={40}
                  color={
                    selectedLanguage === "pt-BR"
                      ? colors.primary.green01
                      : colors.gray.dark02
                  }
                />

                <Styles.CanadaFlagContainer
                  onClick={() => handleChangeSelectedLanguage("en-US")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="54"
                    height="25"
                    viewBox="0 0 565.632 268.848"
                  >
                    <g
                      fill={
                        selectedLanguage === "en-US"
                          ? colors.primary.green01
                          : colors.gray.dark02
                      }
                    >
                      <path d="M128.232 268.848V0H0v268.848h128.232zM565.632 268.848V0H437.831v268.848h127.801zM281.592 257.039h-10.8v-51.623l-57.96 5.398 10.8-20.664L160.704 144l16.704-9.361-16.2-44.135 34.416 8.352 12.744-17.712 43.776 43.704-23.112-84.024 27.504 15.696 25.056-45.217 25.055 45.217 28.008-15.696-23.616 84.024 44.208-43.704 12.816 17.712 33.913-8.352-16.2 44.135L402.983 144l-63.431 46.15 11.304 20.664-58.033-5.398v51.623h-11.231z" />
                    </g>
                  </svg>
                </Styles.CanadaFlagContainer>
              </Styles.FlagsDiv>
            </Styles.InternationalizationDiv>

            <Styles.MobileIcon onClick={toggle}>
              <FaBars />
            </Styles.MobileIcon>

            <Styles.NavMenu>
              <Styles.NavItem>
                <Styles.NavLinks
                  to="home"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {Translator("navbar.home")}
                </Styles.NavLinks>
              </Styles.NavItem>

              <Styles.NavItem>
                <Styles.NavLinks
                  to="about"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {Translator("navbar.about")}
                </Styles.NavLinks>
              </Styles.NavItem>

              <Styles.NavItem>
                <Styles.NavLinks
                  to="services"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {Translator("navbar.services")}
                </Styles.NavLinks>
              </Styles.NavItem>

              <Styles.NavItem>
                <Styles.NavLinks
                  to="portfolio"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {Translator("navbar.portfolio")}
                </Styles.NavLinks>
              </Styles.NavItem>

              <Styles.NavItem>
                <Styles.NavLinks
                  to="contact"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {Translator("navbar.contact")}
                </Styles.NavLinks>
              </Styles.NavItem>
            </Styles.NavMenu>
          </Styles.NavbarContainer>
        </Styles.Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
