import styled, { keyframes } from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { GiBrazilFlag, GiUsaFlag } from "react-icons/gi";

import colors from "../../utils/colors";

const animate = keyframes`
  0%, 18%, 20%, 50.1%, 60%, 65.1%, 80%, 90.1%, 92% { 
    color: ${colors.gray.grayDark03};
    text-shadow: none;
  }

  18.1%, 20.1%, 30%, 50%, 60.1%, 65%, 80.1%, 90%, 92.1%, 100% { 
    color: ${colors.gray.white};
    text-shadow: 0 0 10px ${colors.primary.green01}, 
      0 0 20px ${colors.primary.green01},
      0 0 40px ${colors.primary.green01},
      0 0 80px ${colors.primary.green01},
      0 0 160px ${colors.primary.green01},
      0 0 400px ${colors.primary.green01};
  }
`;

export const Nav = styled.nav<{ scrollNav: boolean }>`
  background: ${({ scrollNav }) =>
    scrollNav ? colors.gray.black : "transparent"};
  height: 70px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }

  @media screen and (max-width: 870px) {
    margin-top: -120px;
    height: 110px;
  }
`;

export const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  width: 100%;
  padding: 0 24px;
  max-width: 1280px;
`;

// export const NavLogo = styled(LinkR)`
//   color: ${colors.gray.white};
//   justify-self: flex-start;
//   cursor: pointer;
//   font-size: 1.5rem;
//   display: flex;
//   align-items: center;
//   margin-left: 24px;
//   font-weight: bold;
//   text-decoration: none;
//   transition: all 0.3s ease-in-out;
//   font-family: Orelega One;
//   font-weight: 400;
//   font-size: 24px;
//   letter-spacing: 1px;

//   &:hover {
//     color: ${colors.primary.green02};
//     transform: scale(1.05);
//   }

//   @media screen and (max-width: 870px) {
//     border-bottom: 1px solid ${colors.gray.dark03};
//   }
// `;

export const NavLogo = styled(LinkR)`
  color: ${colors.gray.white};
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 24px;
  text-decoration: none;
  font-family: Orelega One;
  font-weight: 400;
  letter-spacing: 1px;

  h2 {
    font-size: 24px;
    color: ${colors.gray.grayDark03};
    -webkit-box-reflect: below -14px linear-gradient(transparent, #00000040);

    span {
      animation: ${animate} 5s linear infinite;

      &:nth-child(even) {
        animation-delay: 0.4s;
      }
    }
  }

  @media screen and (max-width: 870px) {
    border-bottom: 1px solid ${colors.gray.dark03};
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: ${colors.gray.white};
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkS)<{ exact: string }>`
  color: ${colors.gray.white};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: Dosis;
  font-weight: 400;
  font-size: 20px;

  &.active {
    border-bottom: 3px solid ${colors.primary.green01};
  }

  &:hover {
    color: ${colors.primary.green02};
    transform: scale(1.06);
  }
`;

export const InternationalizationDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 870px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
  }
`;

export const FlagsDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
  padding-left: 10px;
  border-left: 1px solid ${colors.gray.dark03};

  @media screen and (max-width: 870px) {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }
`;

export const BrazilFlag = styled(GiBrazilFlag)`
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
    filter: brightness(150%);
  }

  @media screen and (max-width: 870px) {
    margin-right: 16px;
    margin-left: 22px;
  }
`;

export const UsaFlag = styled(GiUsaFlag)`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
    filter: brightness(150%);
  }
`;

export const CanadaFlagContainer = styled.div`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    filter: brightness(150%);
  }
`;
