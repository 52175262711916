import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

import colors from '../../utils/colors';

import * as Styles from './styles';

interface IButtonNeonProps {
  text: string;
  to: string;
  fontSize?: number;
}

const Button: any = styled(Link) <{ fontSize: string }>`
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: ${colors.gray.white};
  text-transform: uppercase;
  letter-spacing: 4px;
  text-decoration: none;
  font-size: ${({ fontSize = 16 }: any) => fontSize / 10}rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  font-weight: 500;

  &:hover {
    color: ${colors.gray.black};
    background: ${colors.primary.green01};
    box-shadow: 0 0 10px ${colors.primary.green01}, 
                0 0 40px ${colors.primary.green01}, 
                0 0 80px ${colors.primary.green01};
    transition-delay: 0.5s;
    transform: scale(1.1);

    span {
      &:nth-child(1) {
        left: 100%;
        transition: 0.5s;
      }

      &:nth-child(2) {
        top: 100%;
        transition: 0.5s;
        transition-delay: 0.125s;
      }

      &:nth-child(3) {
        right: 100%;
        transition: 0.5s;
        transition-delay: 0.25s;
      }

      &:nth-child(4) {
        bottom: 100%;
        transition: 0.5s;
        transition-delay: 0.375s;
      }
    }
  }

  span {
    position: absolute;
    display: block;

    &:nth-child(1) {
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, ${colors.primary.green01});
    }

    &:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, ${colors.primary.green01});
    }

    &:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, ${colors.primary.green01});
    }

    &:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, ${colors.primary.green01});
    }
  }
`;

const ButtonNeon: React.FC<IButtonNeonProps> = ({ text, to, fontSize }) => {
  return (
    <>
      <Styles.Container>
        <Button to={to} fontSize={fontSize}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          {text}
        </Button>
      </Styles.Container>
    </>
  );
};

export default ButtonNeon;
