import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Text from '../../components/Text';
import Translator from '../../components/I18n/Translator';
import colors from '../../utils/colors';

import engenhariaDeSoftwareLogo from '../../assets/engenhariaDeSoftwareLogo.svg';
import entregaContinuaogo from '../../assets/entregaContinuaogo.svg';
import cleanCodeLogo from '../../assets/cleanCodeLogo.svg';
import mudancasIlimitadas from '../../assets/mudancasIlimitadas.svg';
import trabalhoRemotoLogo from '../../assets/trabalhoRemotoLogo.svg';
import reactLogo from '../../assets/reactLogo.svg';

import * as Styles from './styles';

const DifferentialsSlickSlider: React.FC = () => {
  const [aosConfig, setAosConfig] = useState({});

  useEffect(() => {
    AOS.init();

    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,

      autoplay: true,
      autoplaySpeed: 5000,
      cssEase: "ease-in-out",
      speed: 1000,
    };

    setAosConfig(settings);
  }, []);

  return (
    <Styles.Container data-aos="zoom-in-right" data-aos-duration="1000">
      <Styles.ContainerBackgroundLineOne />
      <Styles.ContainerBackgroundLineTwo />
      <Styles.ContainerBackgroundLineThree />

      <Text
        text={Translator('about.differentialsTitle')}
        align="center"
        color={colors.gray.light02}
        size={10}
        weight="500"
        marginTop={4}
        fontFamily="Roboto Slab"
      />

      <Styles.StyledSlider {...aosConfig}>
        <Styles.SliderContainer>
          <Styles.SliderImage src={engenhariaDeSoftwareLogo} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.differentialsEngineeringTitle')}
              align="left"
              color={colors.gray.light03}
              size={10}
              weight="700"
              fontFamily="Dosis"
            />

            <Text
              text={Translator('about.differentialsEngineeringText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>

        <Styles.SliderContainer>
          <Styles.SliderImage src={entregaContinuaogo} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.differentialsSubmitTitle')}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="700"
              fontFamily="Dosis"
            />

            <Text
              text={Translator('about.differentialsSubmitText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>

        <Styles.SliderContainer>
          <Styles.SliderImage src={cleanCodeLogo} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.differentialsClearTitle')}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="700"
              fontFamily="Dosis"
            />

            <Text
              text={Translator('about.differentialsClearText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>

        <Styles.SliderContainer>
          <Styles.SliderImage src={mudancasIlimitadas} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.differentialsChangesTitle')}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="700"
              fontFamily="Dosis"
            />

            <Text
              text={Translator('about.differentialsChangesText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>

        <Styles.SliderContainer>
          <Styles.SliderImage src={trabalhoRemotoLogo} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.differentialsWorkTitle')}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="700"
              fontFamily="Dosis"
            />

            <Text
              text={Translator('about.differentialsWorkText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>

        <Styles.SliderContainer>
          <Styles.SliderImage src={reactLogo} />

          <Styles.SliderTextDiv>
            <Text
              text={Translator('about.differentialsReactTitle')}
              align="left"
              color={colors.gray.light02}
              size={10}
              weight="700"
              fontFamily="Dosis"
            />

            <Text
              text={Translator('about.differentialsReactText')}
              align="left"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </Styles.SliderTextDiv>
        </Styles.SliderContainer>
      </Styles.StyledSlider>
    </Styles.Container>
  );
};

export default DifferentialsSlickSlider;
