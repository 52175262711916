/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from 'react';
import {
  AiOutlineCloseSquare, AiOutlineSwapLeft, AiOutlineSwapRight
} from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import FooterSection from '../../sections/FooterSection';

import Text from '../../components/Text';
import LoaderContainer from '../../components/LoaderContainer';
import Translator from '../../components/I18n/Translator';

import colors from '../../utils/colors';
import projectsList from '../../utils/projectsList';
import numbertoword from '../../utils/numbertoword';

import i18n from '../../config/i18n';

import * as Styles from './styles';

const ProjectDetails: React.FC = () => {
  const location = useLocation();

  const [projectID, setProjectID] = useState(0);
  const [projectListLength, setProjectListLength] = useState(0);

  const [projectNumberToWord, setProjectNumberToWord] = useState('');

  const handlePassProject = useCallback(() => {
    if (projectID + 1 === projectListLength) {
      setProjectID(0);

      const newProjectNumber = 1;
      setProjectNumberToWord(`${numbertoword(newProjectNumber)}`.replace(/\s/g, ''));
    } else {
      setProjectID(projectID + 1);

      const newProjectNumber = Number(Number(projectID) + 2);
      setProjectNumberToWord(`${numbertoword(newProjectNumber)}`.replace(/\s/g, ''));
    }
  }, [projectID, projectListLength]);

  const handleBackProject = useCallback(() => {
    if (projectID - 1 >= 0) {
      setProjectID(projectID - 1);

      const newProjectNumber = Number(Number(projectID));
      setProjectNumberToWord(`${numbertoword(newProjectNumber)}`.replace(/\s/g, ''));
    } else {
      setProjectID(projectListLength - 1);

      const newProjectNumber = Number(Number(projectListLength));
      setProjectNumberToWord(`${numbertoword(newProjectNumber)}`.replace(/\s/g, ''));
    }
  }, [projectID, projectListLength]);

  const handleOpenGit = useCallback(() => {
    window.open(
      `${projectsList[projectID].gitHubLink}`,
      '_blank'
    )!.focus();
  }, [projectID]);

  const handleOpenSite = useCallback(() => {
    window.open(
      `${projectsList[projectID].siteLink}`,
      '_blank'
    )!.focus();
  }, [projectID]);

  const loader = useCallback(() => {
    // @ts-ignore: Unreachable code error
    document.querySelector('.loader-container').classList.add('fade-out');
  }, []);

  const fadeOut = useCallback(() => {
    setInterval(loader, 1000);

    return
  }, [loader]);

  useEffect(() => {
    try {
      console.log(' ');
      console.log(' ');
      console.log(
        '%c Made with ❤️ by Guilherme Bafica.',
        'color: #01bf71; background: #010606; border-radius: 4px; padding: 10px; font-size: 12px; font-weight: bold'
      );
      console.log(' ');
      console.log(' ');

      window.onload = fadeOut;

      const [, tempProjectId] = location.pathname.split('/project-details/');

      setProjectID(Number(tempProjectId));

      setProjectListLength(projectsList.length)

      const newProjectNumber = Number(Number(tempProjectId) + 1);

      setProjectNumberToWord(`${numbertoword(newProjectNumber)}`.replace(/\s/g, ''));
    } catch (error) {
      let message = 'Houve um erro ao carregar os detalhes do projeto :(';

      if (i18n.language === 'en-US') {
        message = 'Sorry, there was an error loading the project details :(';
      }

      toast(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });
    }
  }, [location.pathname, fadeOut]);

  return (
    <>
      <LoaderContainer />

      <Styles.Container>
        <Styles.CloseButtonDiv>
          <Styles.NavProjectDiv>
            <Styles.NavProjectButton onClick={handleBackProject}>
              <AiOutlineSwapLeft size={40} color={colors.gray.white} />
            </Styles.NavProjectButton>

            <Styles.NavProjectButton onClick={handlePassProject}>
              <AiOutlineSwapRight size={40} color={colors.gray.white} />
            </Styles.NavProjectButton>
          </Styles.NavProjectDiv>

          <Styles.CloseButton>
            <Link to="/">
              <AiOutlineCloseSquare size={40} color={colors.gray.black} />
            </Link>
          </Styles.CloseButton>
        </Styles.CloseButtonDiv>

        <Styles.Content>
          <Styles.ProjectPicDiv>
            <Styles.IMacHeader>
              <Styles.IMacScreenDiv>
                <Styles.ProjectImg src={`${projectsList[projectID].imgArray[0]}`} />
              </Styles.IMacScreenDiv>
            </Styles.IMacHeader>

            <Styles.IMacMiddle>
              <Styles.IMacIcon />
            </Styles.IMacMiddle>

            <Styles.IMacFoot />
          </Styles.ProjectPicDiv>

          <Text
            text={Translator(`portfolio.project${projectNumberToWord}Title`)}
            align="left"
            color={colors.gray.light03}
            size={24}
            weight="500"
            fontFamily="Roboto Slab"
          />

          <Text
            text={Translator(`projectDetail.project${projectNumberToWord}TextOne`)}
            align="left"
            color={colors.gray.light01}
            size={14}
            weight="400"
            fontFamily="Dosis"
            marginTop={16}
          />

          <Text
            text={Translator(`projectDetail.project${projectNumberToWord}TextTwo`)}
            align="left"
            color={colors.gray.light01}
            size={14}
            weight="400"
            fontFamily="Dosis"
            marginTop={8}
          />

          <Styles.ProjectLinksDiv>
            {projectsList[projectID].gitHubLink !== undefined && (
              <Styles.GitHubIcon onClick={handleOpenGit} />
            )}

            {projectsList[projectID].siteLink !== undefined && (
              <Styles.WorldIcon onClick={handleOpenSite} />
            )}
          </Styles.ProjectLinksDiv>
        </Styles.Content>

        <Styles.AnotherProjects>
          <Styles.AnotherProjectsMessage>
            <Styles.StyledLink to="/">
              <BsChevronLeft color={colors.gray.light03} size={24} />

              <Text
                text={Translator('projectDetail.anotherText')}
                align="center"
                color={colors.gray.light03}
                size={12}
                weight="500"
                fontFamily="Dosis"
              />
            </Styles.StyledLink>
          </Styles.AnotherProjectsMessage>
        </Styles.AnotherProjects>

        <FooterSection />
      </Styles.Container >
    </>
  );
};

export default ProjectDetails;
