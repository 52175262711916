import styled, { keyframes } from 'styled-components';

import wav02 from '../../assets/wave02.png';
import wave from '../../assets/wave.png';
import wave03 from '../../assets/wave03.png';
import serviceBackgroud from '../../assets/serviceBackgroud.png';

import colors from '../../utils/colors';

const waveAnimation = keyframes`
  0% { background-position-x: 0 }
  100% { background-position-x: 1000px }
`;

const waveAnimation2 = keyframes`
  0% { background-position-x: 0 }
  100% { background-position-x: -1000px }
`;

export const ServiceContainer = styled.div`
  background: ${colors.gray.grayDark01};
  overflow: hidden !important;
`;

export const WavyShape = styled.div`
  background: ${colors.gray.grayDark01};

  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 20%;
  vertical-align: middle;
  overflow: hidden;
`;

export const WaveOne = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${wav02});
  background-size: 1000px 100px;
  transform: rotate(180deg);

  animation: ${waveAnimation} 30s linear infinite;
  z-index: 9;
  opacity: 1;
  animation-delay: 0s;
`;

export const WaveTwo = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  width: 100%;
  height: 100px;
  background: url(${wave03});
  background-size: 1000px 100px;
  transform: rotate(180deg);

  animation: ${waveAnimation2} 15s linear infinite;
  z-index: 8;
  opacity: 0.3;
  animation-delay: -5s;
`;

export const WaveThree = styled.div`
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${wave});
  background-size: 1000px 100px;
  transform: rotate(180deg);

  animation: ${waveAnimation} 30s linear infinite;
  z-index: 7;
  opacity: 0.1;
  animation-delay: -2s;
`;

export const WaveFour = styled.div`
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${wave});
  background-size: 1000px 100px;
  transform: rotate(180deg);

  animation: ${waveAnimation2} 15s linear infinite;
  z-index: 6;
  opacity: 0.05;
  animation-delay: -5s;
`;

export const WaveFive = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${wav02});
  background-size: 1000px 100px;

  animation: ${waveAnimation} 30s linear infinite;
  z-index: 9;
  opacity: 1;
  animation-delay: 0s;
`;

export const WaveSix = styled.div`
  position: absolute;
  bottom: 0;
  left: 10px;
  width: 100%;
  height: 100px;
  background: url(${wave03});
  background-size: 1000px 100px;

  animation: ${waveAnimation2} 15s linear infinite;
  z-index: 8;
  opacity: 0.3;
  animation-delay: -5s;
`;

export const WaveSeven = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${wave});
  background-size: 1000px 100px;

  animation: ${waveAnimation} 30s linear infinite;
  z-index: 7;
  opacity: 0.1;
  animation-delay: -2s;
`;

export const WaveEight = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${wave});
  background-size: 1000px 100px;

  animation: ${waveAnimation2} 15s linear infinite;
  z-index: 6;
  opacity: 0.05;
  animation-delay: -5s;
`;

export const Content = styled.div`
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ServicesDiv = styled.div`
  width: 100%;
  border: 1px dashed ${colors.gray.light01};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;  
  overflow: hidden;
  padding: 3rem;
  margin-top: 40px;
  margin-bottom: 40px;
  background: url(${serviceBackgroud}) no-repeat;
  background-size: cover;
  background-position: center;
  max-width: 1280px;
`;

export const ServiceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 50%;  

  &+div {
    border-left: 2px solid ${colors.primary.lightGreen01};
    z-index: 1;
  }

  @media screen and (max-width: 770px) {
    width: 90%;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 10px;
  }

  @media screen and (max-width: 400px) {
    margin-bottom: 20px;
  }
`;

export const ServiceItemTwo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 50%;
  margin-right: 30px;

  &+div {
    border-left: 2px solid ${colors.primary.lightGreen01};
    z-index: 1;
  }

  @media screen and (max-width: 770px) {
    margin-right: 0;
    width: 90%;
    border-bottom: 1px solid ${colors.primary.lightGreen01};
    padding-bottom: 20px;
    margin-bottom: -20px;

    border-top: 1px solid ${colors.primary.lightGreen01};
    padding-top: 20px;
    margin-top: -20px;

    &+div {
      border-left: none;
    }
  }
`;

export const ServiceItemThree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  height: 50%;
  margin-right: 30px;
  padding-left: 30px;

  &+div {
    border-left: 2px solid ${colors.primary.lightGreen01};
    z-index: 1;
  }

  @media screen and (max-width: 770px) {
    margin-right: 0;
    padding-left: 0;
    margin-top: 38px;
    width: 90%;
  }
`;

export const ServiceItemPic = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: linear-gradient(-145deg, #e34f26, #e44d31);
`;

export const LogoImg = styled.img`
  height: 90%;
  width: 90%;
  -o-object-fit: cover;
  object-fit: contain;
`;

export const ServiceItemPicTwo = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: linear-gradient(-145deg, #50aef9, #007cf2);
`;

export const ServiceItemPicThree = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: linear-gradient(-145deg, #3b3b3b, #0d0d0d);
`;

export const ServiceItemVDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 770px) {
    display: none;
  }
`;

export const ServiceItemLineOne = styled.div`
  width: 40%;
  height: 2px;
  background: ${colors.primary.lightGreen01};
  transform: rotate(25deg);
  position: relative;
  left: 2%;
`;

export const ServiceItemLineTwo = styled.div`
  width: 40%;
  height: 2px;
  background: ${colors.primary.lightGreen01};
  transform: rotate(-25deg);
  position: relative;
  right: 2%;
`;

export const ServiceItemBottomDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 8.4%;

  @media screen and (max-width: 770px) {
    flex-direction: column;
  }
`;

export const TitleDots = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 20px;
  background: ${colors.primary.green01};
  margin-left: 10px;
  margin-right: 10px;

  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const HeaderTitleDiv = styled.div`
  margin-top: 80px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
