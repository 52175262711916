import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ScrollToTop from './helpers';

import Dashboard from './pages/Dashboard';
import ProjectDetails from './pages/ProjectDetails';
import NotFound from './pages/NotFound';

const Routes = () => (
  <Router>
    <ScrollToTop />

    <Switch>
      <Route exact={true} path="/" component={Dashboard} />
      <Route
        exact={true}
        path="/project-details/:projetctID"
        component={ProjectDetails}
      />

      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
);

export default Routes;
