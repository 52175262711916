import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

import colors from '../../utils/colors';

export const SidebarContainer = styled.aside<{ isOpen: boolean }>`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: ${colors.gray.grayDark01};
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

export const CloseIcon = styled(FaTimes)`
  color: ${colors.gray.white};
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SideBarWrapper = styled.div`
  color: ${colors.gray.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const SidebarLink = styled(LinkS)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  list-style: none;
  transition: all 0.3s ease-in-out;
  color: ${colors.gray.white};
  cursor: pointer;
  font-family: Dosis;
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 12px;

  &:hover {
    color: ${colors.primary.green01};
    transform: scale(1.06);
  }
`;

export const SideBtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const SidebarRoute = styled(LinkR)`
  border-radius: 50px;
  background: ${colors.primary.green01};
  white-space: nowrap;
  padding: 16px 64px;
  color: ${colors.gray.grayDark07};
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  text-decoration: none;

  &:hover {
    background: ${colors.gray.white};
  }
`;

