export default {
  translations: {
    navbar: {
      home: "Home",
      about: "Who I am",
      services: "What I do",
      portfolio: "Portfolio",
      contact: "Contact",
    },

    home: {
      title: "I don't sell products, I offer you a personalized experience.",
      subTitle: "Ready to transform your ideas into reality?",
      button: "START",
    },

    about: {
      citationTitle: "CITATION",
      citationSubTittle:
        "Train while they sleep, study while they have fun, persist while they rest, and then live what they dream.",
      citationWriter: "Japanese proverb",

      occupationTitle: "CURRENT OCCUPATION",
      occupationSubTitle: "Full-Stack web and mobile Developer",

      hello: "Hi,",
      cvDownload: "DOWNLOAD RESUME",

      skillsTitle: "- SKILLS AND FORMATION -",

      differentialsTitle: "- DISTINCTION -",

      differentialsEngineeringTitle: "Software Engineering",
      differentialsEngineeringText:
        "I use optimized methodologies and planning that streamline the project's flow, from the planning stage to the moment of submit.",

      differentialsSubmitTitle: "Continuous Submit",
      differentialsSubmitText:
        "Based on constant feedbacks and interactions with the client, I will always send project updates, ensuring a fluidity during the development process.",

      differentialsClearTitle: "Clear Code",
      differentialsClearText:
        "I always use the best practices in the market, because the quality of my codes is the most important factor.",

      differentialsChangesTitle: "Unlimited Changes",
      differentialsChangesText:
        "During the development process, the customer has the possibility to make as many changes as necessary.",

      differentialsWorkTitle: "Remote Work",
      differentialsWorkText:
        "All project development process can be done remotely. Receive your product in the comfort of your home.",

      differentialsReactTitle: "React Library",
      differentialsReactText:
        "I work with the same technologies that are used by market giant such as: Netflix, Whatsapp web, Instagram, Airbnb, Walmart, Facebook...",

      formationsTitle: "- FORMATIONS -",

      formationsRocketTitle: "ROCKETSEAT 🚀",
      formationsRocketText:
        "Graduated in 2020 by Rocketseat in hybrid development using the React stack.",

      formationsReactTitle: "Computer Engineering 🧑‍🎓",
      formationsReactText:
        "Leader, Representative, and Undergraduate in Computer Engineering.",
    },

    services: {
      tittle: "WHAT I DO",

      webTitle: "WEBSITES",
      webSubTitle: "Web Systems",
      webSubTextOne: "Development of single pages",
      webSubTextTwo:
        "as a portfolio, to complete management systems for your company.",

      appTitle: "APPLICATIONS",
      appSubTitle: "Android and IOS hybrids",
      appSubTextOne: "Hybrid application development",
      appSubTextTwo: "both for Android and IOS phones.",

      apiTitle: "API",
      apiSubTitle: "Backend",
      apiSubTextOne: "API's development",
      apiSubTextTwo: "in the RESTful pattern with all routes to your system.",

      button: "KNOW MORE",
    },

    portfolio: {
      welcomeMessage: "Welcome to my portfolio!",
      button: "Know more",

      projectOneTitle: "euCEI",
      projectOneSubTitle: "University event management system.",

      projectTwoTitle: "airCnC",
      projectTwoSubTitle: 'Coffee and Code: airBnB "clone".',

      projectThreeTitle: "ECOLETA",
      projectThreeSubTitle: "Map for waste collection points.",

      projectFourTitle: "MapaDev",
      projectFourSubTitle: "Simple application with geolocation.",

      projectFiveTitle: "BeTheHero",
      projectFiveSubTitle: "NGO registration and viewer.",

      projectSixTitle: "goBarber",
      projectSixSubTitle: "Barber appointment system.",

      projectSevenTitle: "Hospital S.",
      projectSevenSubTitle: "Complete web hospital management system.",

      projectEightTitle: "Happy",
      projectEightSubTitle: "Tool to promote orphanages.",

      projectNineTitle: "ELIXR",
      projectNineSubTitle: "Property sale website.",

      projectTenTitle: "Podcastr",
      projectTenSubTitle: "Podcast post website.",

      projectElevenTitle: "GoNutri",
      projectElevenSubTitle: "Nutrition portfolio.",

      projectTwelveTitle: "Kindergarten",
      projectTwelveSubTitle: "Landing page for nursery.",

      projectThirteenTitle: "ODAN",
      projectThirteenSubTitle: "Puppy monitoring.",

      projectFourteenTitle: "RO Engenharia",
      projectFourteenSubTitle: "Civil engineering portfolio.",
    },

    contact: {
      title: "NEED A SYSTEM?",
      subTitle: "Let's work together",
    },

    footer: {
      menuTitle: "MENU",
      menuHome: "Home",
      menuAbout: "About",
      menuServices: "Services",
      menuPortfolio: "Portfolio",
      menuContact: "Contact",

      socialTitle: "SOCIAL NETWORKS",
      socialGitHub: "GitHub",
      socialLinkedIn: "LinkedIn",
      socialInstagram: "Instagram",

      contactTitle: "CONTACT",
      contactWhatsApp: "WhatsApp",
      contactEmail: "Email",
      contactPhone: "Cell phone",

      reservedMessage:
        "© Copyright Guilherme Bafica Developer 2021 | All rights reserved",
    },

    projectDetail: {
      projectOneTextOne: `
        This project was my gateway to the world of web programming and
        mobile. Developed together with two more friends, euCEI was sold
        for the Center for Entrepreneurship and Innovation at Wyden University Area 1
        university.
      `,
      projectOneTextTwo: `
        Its main objective was, through the website, to facilitate the 
        registration of events by the university coordinators, where they would 
        also be able to manage subscribers, update information, and even delete 
        your event registered. Through the web, the coordinator, at the end of 
        the event, could easily generate the additional workload of enrolled students.

        On the other hand, in the application, registered students would have 
        the possibility and ease of viewing, in one place, all the events that 
        are happening on campus, with an interest filter. If interested, the 
        student could subscribe with just one click.
      `,

      projectTwoTextOne: `
        My first web and mobile project. It had a functional working premise
        on AirBnB, however, aimed at the dev world.
      `,
      projectTwoTextTwo: `
        On the website, users could register places dedicated to programming, spaces
        those that would be rented by other people who would have an interest in the space.

        In the app, interested parties could request to reserve these spaces
        previously registered.
      `,

      projectThreeTextOne: `
        The application has the functionality to help people find collection 
        points efficiently.
      `,
      projectThreeTextTwo: `
        With the help of a map, the user can easily find points previously 
        registered, in the proximity of your current location. The system
        it also has a filter, where there is the possibility to select exactly
        the stuff you'd like to discard.

        Collection companies have the functionality to register their points and
        the type of collection material.
      `,

      projectFourTextOne: `
        In this application, there was the implementation of a map, with this, 
        you can view registered people around you.
      `,
      projectFourTextTwo: `
        The objective would be to find nearby programmers, both in locality,
        how much in interest.
      `,

      projectFiveTextOne: `
        Features that enable Heroes to visualize and help NGOs
        registered on the platform.
      `,
      projectFiveTextTwo: `
        In resume, through the website, NGOs can register, with name, values,
        case description and location. With that, through the apps, interested people
        can view this information, get in touch and help with the cause.
      `,

      projectSixTextOne: `
        The purpose is to assist the flow of customers in a barbershop, with
        facility in scheduling and managing clients and professionals.
      `,
      projectSixTextTwo: `
        My first project focused exclusively on good practices, in it, I was instructed
        to apply the best techniques on the market at the time. I acquired a lot 
        of knowledge that I apply to my projects currently.
      `,

      projectSevenTextOne: `
        This project had as its main auxiliary functionality and streamline the flow
        of appointments in a hospital clinic. With that, being able to offer a service
        with higher rate of quality, efficiency, speed and organization.
      `,
      projectSevenTextTwo: `
        For the exclusive use of clinic employees, each user had a specific
        level of access within the system, such as administrators, who
        they were allowed to register new employees.
        The system was divided into modules:
          In the appointments section, the receptionists
        they could select the patient, date, procedure, for which doctor and even
        even write a description about the case.
          On the home page, the registered exams were displayed, in order of period 
          and filtering doctor, where each one had isolated access to their own schedules.
          The system also has a patient record, where each one has their history
        of the medical record, so that the doctor can evaluate all the procedures of that
        patient since it was registered in the system, regardless of how long
        this has occurred, everything is saved in the system. With that, we can apply
        a preventive and more complete medicine.
          In the system there is the notifications tab, which notifies, for example, the
        receptionists when a patient needs to make a return visit, and
        makes available the option to send a direct message to the patient's Whatsapp in
        just 1 click.
          There is a warehouse control section, notifying you if any item or medication
        is close to spoiling or low in quantity.
          And another important point is the chat module. In it, employees can
        chat with each other, in individual chats, as if it were a Whatsapp. making it easy
        communication between sectors, and even serving to notify the medic that
        your patient has arrived.
      `,

      projectEightTextOne: `
        This is Happy, a complete application that has as its main objective
        promote orphanages and bring joy to your children.
      `,
      projectEightTextTwo: `
        With the aid of a map, the user can view nearby orphanages
        of your location, with some information about it, such as what it is
        necessary to be able to make a visit.
      `,

      projectNineTextOne: `
        Fully Responsive Website Design with Animations.
      `,
      projectNineTextTwo: `
        This repository is for study purposes, and represents a landing page of a
        website for real estate sales, rentals and advertisements.
      `,

      projectTenTextOne: `
        A web application for listening to a podcast.
      `,
      projectTenTextTwo: `
        The site is based on a single podcast, the purpose is to post the content
        coming from just one source.
      `,

      projectElevenTextOne: `
        Personal website for a nutritionist, in the form of a portfolio.
      `,
      projectElevenTextTwo: `
        In this project, you will find information about the professional, tips for
        feed in the form of cards, a detailed layout, responsive and with
        animations.
      `,

      projectTwelveTextOne: `
        Website with detailed design and lot off illustrations. (Project under development)
      `,
      projectTwelveTextTwo: `
        This project simulates a landing page for a kindergarten or nursery school. 
        In it, users will find the design pleasing and childlike. During the
        website, information is displayed, such as company strengths, description,
        games and dynamics made available, description of teachers, values ​​and
        contact section.
      `,

      projectThirteenTextOne: `
        ODAN - Observatory for the Development and Monitoring of the puppies. 
      `,
      projectThirteenTextTwo: `
        Project developed for the purpose of controlling some puppies, where it should be
        containing information such as vaccination, location, puppy name and current tutor.
      `,

      projectFourteenTextOne: `
        RO ENGENHARIA is a civil engineering company focused on development
        solutions in projects and real estate regularization. Determined to make 
        dreams come true, with the primary point of care to customers and 
        qualification of professionals.
      `,
      projectFourteenTextTwo: `
        This project is a web portfolio that aims to display information about
        the company as services, team and contact.
      `,

      anotherText: "OTHER PROJECTS",
    },

    notFound: {
      error: "Error ",
      titleError: "Oops, page not found.",
      infoError: `
        The page you are looking for does not exist, have been removed, or is 
        temporarily unavailable.
      `,
      finalError: `Don't worry, just `,
      buttonError: "click here and return to the homepage",
    },
  },
};
