import React from 'react';
import { Link } from 'react-router-dom';
import { BiDesktop } from 'react-icons/bi';
import { GoDeviceMobile } from 'react-icons/go';

import Translator from '../../components/I18n/Translator';
import Text from '../../components/Text';
import colors from '../../utils/colors';
import limitString from '../../utils/limitString';

import * as Styles from './styles';

interface IProjectCardProps {
  src: string;
  title: string;
  body: string;
  projectIndex: number;
  hasDesktop: boolean;
  hasMobile: boolean;
}

const ProjectCard: React.FC<IProjectCardProps> = ({
  src,
  title,
  body,
  projectIndex,
  hasDesktop,
  hasMobile,
}) => {
  // const handleNavigateToDetails = useCallback(() => {
  //   window.open(
  //     `https://guilhermebafica.com.br/project-details/${projectIndex}`,
  //     '_blank'
  //   )!.focus();
  // }, [projectIndex]);

  return (
    <>
      {/* <Styles.Container onClick={handleNavigateToDetails}> */}
      <Styles.Container>
        <Styles.ProjectCardImgDiv>
          <Styles.ProjectCardImg src={src} />
        </Styles.ProjectCardImgDiv>

        <Styles.ProjectCardContent>
          <div>
            <Text
              text={limitString(title, 10)}
              align="center"
              color={colors.gray.light03}
              size={20}
              weight="400"
              fontFamily="Dosis"
              marginTop={4}
            />

            <Text
              text={limitString(body, 56)}
              align="center"
              color={colors.gray.light01}
              size={10}
              weight="400"
              marginTop={4}
              fontFamily="Dosis"
            />
          </div>

          <Styles.MoreInformationDiv>
            <Styles.ProjectPlatformDiv>
              <BiDesktop
                color={hasDesktop ? colors.gray.white : colors.gray.grayDark04}
                size={28}
              />

              <GoDeviceMobile
                color={hasMobile ? colors.gray.white : colors.gray.grayDark04}
                size={22}
              />
            </Styles.ProjectPlatformDiv>

            <Styles.MoreInformationButton>
              <Link
                to={`/project-details/${projectIndex}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {Translator('portfolio.button')}
              </Link>
            </Styles.MoreInformationButton>
          </Styles.MoreInformationDiv>
        </Styles.ProjectCardContent>
      </Styles.Container>
    </>
  );
};

export default ProjectCard;
