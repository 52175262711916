import styled from "styled-components";

import colors from "../../utils/colors";

export const Container = styled.div`
  .loader-container.fade-out {
    top: 110%;
    opacity: 0;
  }
`;

export const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000000;
  background: ${colors.gray.black};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const LoaderImg = styled.img`
  width: 20rem;
`;
