import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Text from '../../components/Text';
import ButtonNeon from '../../components/ButtonNeon';
import Translator from '../../components/I18n/Translator';
import colors from '../../utils/colors';

import computerLogo from '../../assets/computerLogo.svg';
import mobileLogo from '../../assets/mobileLogo.svg';
import apiLogo from '../../assets/apiLogo.svg';

import * as Styles from './styles';

const ServicesSection: React.FC = () => {
  AOS.init();

  return (
    <>
      <Styles.ServiceContainer id="services">
        <Styles.WavyShape>
          <Styles.WaveOne />
          <Styles.WaveTwo />
          <Styles.WaveThree />
          <Styles.WaveFour />

          <Styles.Content>
            <Styles.HeaderTitleDiv>
              <Styles.TitleDots />
              <Styles.TitleDots />
              <Styles.TitleDots />
              <Styles.TitleDots />
              <Styles.TitleDots />

              <Text
                text={Translator('services.tittle')}
                align="center"
                color={colors.primary.green01}
                size={20}
                weight="500"
                fontFamily="Roboto Slab"
                letterSpacing={2}
                marginRight={8}
                marginLeft={8}
              />

              <Styles.TitleDots />
              <Styles.TitleDots />
              <Styles.TitleDots />
              <Styles.TitleDots />
              <Styles.TitleDots />
            </Styles.HeaderTitleDiv>

            <Styles.ServicesDiv>
              <Styles.ServiceItem data-aos="fade-down" data-aos-duration="1000">
                <Styles.ServiceItemPic>
                  <Styles.LogoImg src={computerLogo} />
                </Styles.ServiceItemPic>

                <Text
                  text={Translator('services.webTitle')}
                  align="center"
                  color={colors.gray.light02}
                  size={16}
                  weight="400"
                  marginTop={4}
                  fontFamily="Roboto Slab"
                />

                <Text
                  text={Translator('services.webSubTitle')}
                  align="center"
                  color={colors.gray.light03}
                  size={10}
                  weight="400"
                  fontFamily="Dosis"
                  marginTop={4}
                />

                <Text
                  text={Translator('services.webSubTextOne')}
                  align="center"
                  color={colors.gray.light01}
                  size={10}
                  weight="400"
                  marginTop={12}
                  fontFamily="Dosis"
                />

                <Text
                  text={Translator('services.webSubTextTwo')}
                  align="center"
                  color={colors.gray.light01}
                  size={10}
                  weight="400"
                  fontFamily="Dosis"
                  marginBottom={16}
                />

                <ButtonNeon text={Translator('services.button')} to="contact" fontSize={6} />
              </Styles.ServiceItem>

              <Styles.ServiceItemVDiv>
                <Styles.ServiceItemLineOne />
                <Styles.ServiceItemLineTwo />
              </Styles.ServiceItemVDiv>

              <Styles.ServiceItemBottomDiv>
                <Styles.ServiceItemTwo data-aos="fade-up-right" data-aos-duration="1000">
                  <Styles.ServiceItemPicTwo>
                    <Styles.LogoImg src={mobileLogo} />
                  </Styles.ServiceItemPicTwo>

                  <Text
                    text={Translator('services.appTitle')}
                    align="center"
                    color={colors.gray.light02}
                    size={16}
                    weight="400"
                    marginTop={4}
                    fontFamily="Roboto Slab"
                  />

                  <Text
                    text={Translator('services.appSubTitle')}
                    align="center"
                    color={colors.gray.light03}
                    size={10}
                    weight="400"
                    fontFamily="Dosis"
                    marginTop={4}
                  />

                  <Text
                    text={Translator('services.appSubTextOne')}
                    align="center"
                    color={colors.gray.light01}
                    size={10}
                    weight="400"
                    marginTop={12}
                    fontFamily="Dosis"
                  />

                  <Text
                    text={Translator('services.appSubTextTwo')}
                    align="center"
                    color={colors.gray.light01}
                    size={10}
                    weight="400"
                    fontFamily="Dosis"
                    marginBottom={16}
                  />

                  <ButtonNeon text={Translator('services.button')} to="contact" fontSize={6} />
                </Styles.ServiceItemTwo>

                <Styles.ServiceItemThree data-aos="fade-up-left" data-aos-duration="1000">
                  <Styles.ServiceItemPicThree>
                    <Styles.LogoImg src={apiLogo} />
                  </Styles.ServiceItemPicThree>

                  <Text
                    text={Translator('services.apiTitle')}
                    align="center"
                    color={colors.gray.light02}
                    size={16}
                    weight="400"
                    marginTop={4}
                    fontFamily="Roboto Slab"
                  />

                  <Text
                    text={Translator('services.apiSubTitle')}
                    align="center"
                    color={colors.gray.light03}
                    size={10}
                    weight="400"
                    fontFamily="Dosis"
                    marginTop={4}
                  />

                  <Text
                    text={Translator('services.apiSubTextOne')}
                    align="center"
                    color={colors.gray.light01}
                    size={10}
                    weight="400"
                    marginTop={12}
                    fontFamily="Dosis"
                  />

                  <Text
                    text={Translator('services.apiSubTextTwo')}
                    align="center"
                    color={colors.gray.light01}
                    size={10}
                    weight="400"
                    fontFamily="Dosis"
                    marginBottom={16}
                  />

                  <ButtonNeon text={Translator('services.button')} to="contact" fontSize={6} />
                </Styles.ServiceItemThree>
              </Styles.ServiceItemBottomDiv>
            </Styles.ServicesDiv>
          </Styles.Content>

          <Styles.WaveFive />
          <Styles.WaveSix />
          <Styles.WaveSeven />
          <Styles.WaveEight />
        </Styles.WavyShape>
      </Styles.ServiceContainer>
    </>
  );
};

export default ServicesSection;
