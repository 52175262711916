import React from "react";

// import loaderimg from "../../assets/loader05.gif"; // Smoke
// import loaderimg from "../../assets/loading2.gif"; // Terminal
// import loaderimg from "../../assets/loading4.gif"; // Fliperama
import loaderimg from "../../assets/loading6.gif"; // Cutting shapes

import * as Styles from "./styles";

const LoaderContainer: React.FC = () => {
  return (
    <>
      <Styles.Container>
        <Styles.LoaderContainer className="loader-container">
          <Styles.LoaderImg src={loaderimg} className="imgdasd" />
        </Styles.LoaderContainer>
      </Styles.Container>
    </>
  );
};

export default LoaderContainer;
