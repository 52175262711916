import styled from 'styled-components';
import Particles from 'react-particles-js';

export const StyledParticles = styled(Particles)`
  width: 100%;
  height: 40%;
  position: absolute;
  cursor: crosshair;

  @media screen and (max-width: 600px) {
    height: 80%;
  }
`;
