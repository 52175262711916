import React from 'react';

import Text from '../../components/Text';
import ProjectCard from '../../components/ProjectCard';
import Translator from '../../components/I18n/Translator';

import colors from '../../utils/colors';
import projectsList from '../../utils/projectsList';

import * as Styles from './styles';

const PortfolioSection: React.FC = () => {
  return (
    <>
      <Styles.PortfolioContainer id="portfolio">
        <Styles.WelcomeMessage>
          <Styles.WelcomeMessageContent>
            <Styles.TextBox>
              <Text
                text={Translator('portfolio.welcomeMessage')}
                align="center"
                color={colors.gray.light01}
                size={12}
                weight="500"
                fontFamily="Roboto Slab"
                letterSpacing={2}
              />
            </Styles.TextBox>
          </Styles.WelcomeMessageContent>
        </Styles.WelcomeMessage>

        <Styles.Content>
          <Styles.ThreeCardsDiv>
            <ProjectCard
              src={projectsList[0].imgArray[0]}
              hasMobile={projectsList[0].hasMobile}
              hasDesktop={projectsList[0].hasDesktop}
              title={Translator('portfolio.projectOneTitle')}
              body={Translator('portfolio.projectOneSubTitle')}
              projectIndex={0}
            />

            <ProjectCard
              src={projectsList[1].imgArray[2]}
              hasMobile={projectsList[1].hasMobile}
              hasDesktop={projectsList[1].hasDesktop}
              title={Translator('portfolio.projectTwoTitle')}
              body={Translator('portfolio.projectTwoSubTitle')}
              projectIndex={1}
            />

            <ProjectCard
              src={projectsList[2].imgArray[0]}
              hasMobile={projectsList[2].hasMobile}
              hasDesktop={projectsList[2].hasDesktop}
              title={Translator('portfolio.projectThreeTitle')}
              body={Translator('portfolio.projectThreeSubTitle')}
              projectIndex={2}
            />
          </Styles.ThreeCardsDiv>

          <Styles.TwoCardsDiv>
            <ProjectCard
              src={projectsList[3].imgArray[0]}
              hasMobile={projectsList[3].hasMobile}
              hasDesktop={projectsList[3].hasDesktop}
              title={Translator('portfolio.projectFourTitle')}
              body={Translator('portfolio.projectFourSubTitle')}
              projectIndex={3}
            />

            <ProjectCard
              src={projectsList[4].imgArray[0]}
              hasMobile={projectsList[4].hasMobile}
              hasDesktop={projectsList[4].hasDesktop}
              title={Translator('portfolio.projectFiveTitle')}
              body={Translator('portfolio.projectFiveSubTitle')}
              projectIndex={4}
            />
          </Styles.TwoCardsDiv>

          <Styles.ThreeCardsDiv>
            <ProjectCard
              src={projectsList[5].imgArray[2]}
              hasMobile={projectsList[5].hasMobile}
              hasDesktop={projectsList[5].hasDesktop}
              title={Translator('portfolio.projectSixTitle')}
              body={Translator('portfolio.projectSixSubTitle')}
              projectIndex={5}
            />

            <ProjectCard
              src={projectsList[6].imgArray[1]}
              hasMobile={projectsList[6].hasMobile}
              hasDesktop={projectsList[6].hasDesktop}
              title={Translator('portfolio.projectSevenTitle')}
              body={Translator('portfolio.projectSevenSubTitle')}
              projectIndex={6}
            />

            <ProjectCard
              src={projectsList[7].imgArray[0]}
              hasMobile={projectsList[7].hasMobile}
              hasDesktop={projectsList[7].hasDesktop}
              title={Translator('portfolio.projectEightTitle')}
              body={Translator('portfolio.projectEightSubTitle')}
              projectIndex={7}
            />
          </Styles.ThreeCardsDiv>

          <Styles.TwoCardsDiv>
            <ProjectCard
              src={projectsList[8].imgArray[1]}
              hasMobile={projectsList[8].hasMobile}
              hasDesktop={projectsList[8].hasDesktop}
              title={Translator('portfolio.projectNineTitle')}
              body={Translator('portfolio.projectNineSubTitle')}
              projectIndex={8}
            />

            <ProjectCard
              src={projectsList[9].imgArray[1]}
              hasMobile={projectsList[9].hasMobile}
              hasDesktop={projectsList[9].hasDesktop}
              title={Translator('portfolio.projectTenTitle')}
              body={Translator('portfolio.projectTenSubTitle')}
              projectIndex={9}
            />
          </Styles.TwoCardsDiv>

          <Styles.ThreeCardsDiv>
            <ProjectCard
              src={projectsList[10].imgArray[1]}
              hasMobile={projectsList[10].hasMobile}
              hasDesktop={projectsList[10].hasDesktop}
              title={Translator('portfolio.projectElevenTitle')}
              body={Translator('portfolio.projectElevenSubTitle')}
              projectIndex={10}
            />

            <ProjectCard
              src={projectsList[11].imgArray[1]}
              hasMobile={projectsList[11].hasMobile}
              hasDesktop={projectsList[11].hasDesktop}
              title={Translator('portfolio.projectTwelveTitle')}
              body={Translator('portfolio.projectTwelveSubTitle')}
              projectIndex={11}
            />

            <ProjectCard
              src={projectsList[12].imgArray[1]}
              hasMobile={projectsList[12].hasMobile}
              hasDesktop={projectsList[12].hasDesktop}
              title={Translator('portfolio.projectThirteenTitle')}
              body={Translator('portfolio.projectThirteenSubTitle')}
              projectIndex={12}
            />
          </Styles.ThreeCardsDiv>

          <Styles.TwoCardsDiv>
            <ProjectCard
              src={projectsList[13].imgArray[1]}
              hasMobile={projectsList[13].hasMobile}
              hasDesktop={projectsList[13].hasDesktop}
              title={Translator('portfolio.projectFourteenTitle')}
              body={Translator('portfolio.projectFourteenSubTitle')}
              projectIndex={13}
            />
          </Styles.TwoCardsDiv>
        </Styles.Content>
      </Styles.PortfolioContainer>
    </>
  );
};

export default PortfolioSection;
