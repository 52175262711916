import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BiWorld } from 'react-icons/bi';
import { AiFillGithub, AiFillApple } from 'react-icons/ai';

import IMacFrame04 from '../../assets/IMacFrame04.png';

import colors from '../../utils/colors';

export const Container = styled.div`
  background: ${colors.gray.grayDark01};
`;

export const CloseButtonDiv = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  z-index: 2;
`;

export const NavProjectDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NavProjectButton = styled.div`
  width: 40px;
  height: 40px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${colors.gray.grayLight04};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-right: 16px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const CloseButton = styled.div`
  width: 60px;
  height: 60px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${colors.gray.light03};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
`;

export const AnotherProjects = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${colors.primary.green01};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AnotherProjectsMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary.green01};
  height: 20px;
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProjectLinksDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 32px;
`;

export const GitHubIcon = styled(AiFillGithub)`
  height: 50px;
  width: 50px;
  color: ${colors.gray.light01};
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${colors.primary.green01};
    transform: scale(1.2);
  }
`;

export const WorldIcon = styled(BiWorld)`
  width: 50px;
  height: 50px;
  color: ${colors.gray.light01};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${colors.primary.green01};
    transform: scale(1.2);
  }
`;

export const ProjectPicDiv = styled.div`
  width: 100%;
  height: 80vh;
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const IMacHeader = styled.div`
  width: 90%;
  height: 80%;
  max-width: 1000px !important;
  border: 40px solid #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 700px) {
    border: 30px solid #000;
  }

  @media screen and (max-width: 560px) {
    border: 20px solid #000;
    width: 100%;
  }

  @media screen and (max-width: 400px) {
    border: 10px solid #000;
  }
`;

export const IMacMiddle = styled.div`
  width: 90%;
  height: 15%;
  max-width: 1000px !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: linear-gradient(90deg, #1a191f, #323136);
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;

export const IMacFoot = styled.div`
  width: 100%;
  height: 20%;
  background-image: url(${IMacFrame04});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const IMacIcon = styled(AiFillApple)`
  height: 50px;
  width: 50px;
  color: ${colors.gray.black};
`;

export const IMacScreenDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden !important;
`;

export const ProjectImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
